<template>
  <v-btn @click="handleBtnClick" :loading="loading" class="common-btn text-none" dark :disabled="isDisable" depressed :class="[type, isDisplayBlockPc && 'block-btn']">
    <img v-if="icon" class="icon" :src="getIcon" alt="" />
    <slot class="icon"></slot>
    <div  v-if="label">{{ label }}</div>
  </v-btn>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'default',
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    isShowElipsis: {
      type: Boolean,
      default: false,
    },
    isDisplayBlockPc: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    handleBtnClick(event) {
      this.$emit('action', event);
    },
  },
  computed: {
    getIcon() {
      return require(`@/assets/images/${this.icon}`);
    },
  },
};
</script>

<style>
.icon {
  margin-right: 8px !important;
}
</style>
