var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$vuetify.breakpoint.width < 1024)?_c('v-app',{class:_vm.$i18n.locale,staticStyle:{"overflow":"hidden"},attrs:{"id":"inspire-mobile"}},[_c('v-app-bar',{staticStyle:{"height":"84px !important","z-index":"99"},attrs:{"app":""}},[_c('v-toolbar-title',{staticClass:"header header-sp"},[_c('div',{staticClass:"header-wrapper",on:{"click":_vm.toHomePage}},[_c('img',{staticClass:"logo-img",attrs:{"src":require("../assets/icons/User.svg"),"alt":""}})])]),_c('div',{staticClass:"breadcrumb-sp"},[_c('breadcrumbs',{attrs:{"items":_vm.breadCrumbText}})],1)],1),_c('v-main',[_c('div',{staticClass:"content-main",class:[
        this.$route.name === 'Change password' ? 'changPass_mb_height' : '',
        _vm.$route.name === 'Reduction Target' ? 'reduction-list' : '',
        _vm.isFullScreen && 'table-full-screen',
      ],attrs:{"id":this.$route.name}},[_c('router-view'),(_vm.drawer && _vm.closeIcon)?_c('div',{staticClass:"menu menu-toggle-wrap"},[_c('div',{staticClass:"close-btn-block"},[_c('div',{staticClass:"menu-icon",attrs:{"id":"menu-close-icon"},on:{"click":function($event){return _vm.openMenu(false)}}},[_c('img',{staticClass:"menu-close",attrs:{"src":require("@/assets/icons/close-menu.svg"),"alt":""}}),_c('img',{staticClass:"menu-close-hover",attrs:{"src":require("@/assets/icons/close-menu-hover.svg"),"alt":""}})])])]):_vm._e()],1),_c('footer-layout',{attrs:{"drawer":_vm.drawer,"app":""}})],1),_c('dialog-popup',{attrs:{"dialog":_vm.dialog,"message":_vm.messageLogout,"logout":true,"closeText":_vm.closeText},on:{"close":function($event){_vm.dialog = false},"logout":_vm.handleLogout}}),_c('error-popup',{attrs:{"dialog":_vm.errorPopup,"message":_vm.errorMessage},on:{"submit":function($event){_vm.errorPopup = false}}})],1):_c('v-app',{class:_vm.$i18n.locale,attrs:{"id":"inspire"}},[_c('div',{staticClass:"user-info",attrs:{"icon":""}},[_c('div',{staticClass:"user-info__name user-mail"},[_c('img',{staticClass:"icons-header user-info__name--icon",attrs:{"src":require("@/assets/icons/avatar.svg"),"alt":""}}),_c('span',{staticClass:"text-header overflow-eclipsis-text",attrs:{"id":"userName"}},[_vm._v(_vm._s(_vm.userMail))])])]),_c('v-app-bar',{staticClass:"header-wrapper-pc",staticStyle:{"min-height":"84px","overflow":"hidden","z-index":"99"},style:(_vm.isExpanded ? '' : ''),attrs:{"app":""}},[_c('div',{staticClass:"header-wrapper",on:{"click":_vm.toHomePage}},[_c('img',{staticClass:"logo-img",attrs:{"src":require("../assets/icons/User.svg"),"alt":""}})]),_c('div',{staticClass:"breadcrumb-pc"},[_c('breadcrumbs',{attrs:{"items":_vm.breadCrumbText}})],1)]),_c('v-main',{class:_vm.isFullScreen && 'table-full-screen',style:(_vm.isExpanded ? 'padding-left: 0 !important' : 'padding-left: 73px !important')},[_c('div',{staticClass:"content-main",class:[
        this.$route.name === 'Change password' ? 'changPass_pc_height' : '',
        this.$route.name === 'otherSetting' ? 'other-setting' : '',
        _vm.$route.name === 'Reduction Target' ? 'reduction-list' : '',
      ]},[_c('router-view')],1),_c('footer-layout',{attrs:{"drawer":_vm.drawer,"app":""},on:{"logout":function($event){_vm.dialog = true}}})],1),_c('dialog-popup',{attrs:{"dialog":_vm.dialog,"message":_vm.messageLogout,"logout":true,"closeText":_vm.closeText},on:{"close":function($event){_vm.dialog = false},"logout":_vm.handleLogout}}),_c('error-popup',{attrs:{"dialog":_vm.errorPopup,"message":_vm.errorMessage},on:{"submit":function($event){_vm.errorPopup = false}}}),(_vm.isLoadingCommon)?_c('LoadingScrren'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }