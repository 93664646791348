import { oldmethod, pcafMethod, newPcafMethod } from '@/constants/export-report';
import i18n from '@/lang/i18n'
import { PERIOD_DASHBOARD } from "@/constants/dashboard";

const dataScopeLayer = {
  scope1: {
    pattern1: {
      method: i18n.t('b_register_product.method_calculated_by_fuel_method_scope1'),
    },
    pattern2: {
      method: i18n.t('b_register_product.method_calculated_by_the_tkm_method_scope1'),
    },
    pattern3: {
      method: i18n.t('b_register_product.method_calculated_by_fuel_efficiency_method_scope1'),
    },
    pattern4: {
      method: i18n.t('b_register_product.method_calculated_activities_other_than_transportation'),
    },
  },
  scope3: {
    category4: {
      pattern2: {
        method: i18n.t('b_register_product.method_how_to_use_the_shipper_responsibility_report_value'),
        sub_method: ''
      },
      pattern4: {
        method: i18n.t('b_register_product.method_transportation'),
        sub_method: i18n.t('b_register_product.method_calculated_by_fuel_method_and_shipping_fee'),
      },
      pattern5: {
        method: i18n.t('b_register_product.method_transportation'),
        sub_method: i18n.t('b_register_product.method_calculated_by_the_tkm_method'),
      },
      pattern6: {
        method: i18n.t('b_register_product.method_transportation'),
        sub_method: i18n.t('b_register_product.method_calculated_by_fuel_efficiency_method'), //method_calculated_by_fuel_efficiency_method
      },
      pattern7: {
        method: i18n.t('b_register_product.method_base'),
        sub_method: i18n.t('b_register_product.method_fuel'),
      },
      pattern8: {
        method: i18n.t('b_register_product.method_base'),
        sub_method: i18n.t('b_register_product.method_electricity'),
      }
    },
    category6: {
      pattern1: {
        method: i18n.t('b_register_product.method_calculated_from_a_passenger_kilometer')
      },
      pattern2: {
        method: i18n.t('b_register_product.method_calculated_by_fuel_method')
      },
      pattern3: {
        method: i18n.t('b_register_product.method_calculated_by_fuel_efficiency_method')
      },
      pattern4: {
        method: i18n.t('b_register_product.method_calculated_from_the_payment_amount')
      },
      pattern5: {
        method: i18n.t('b_register_product.method_calculated_from_the_number_of_accommodation')
      },
      pattern6: {
        method: i18n.t('b_register_product.method_calculated_from_the_number_of_business_trip_days')
      },
      pattern7: {
        method: i18n.t('b_register_product.method_calculated_from_the_number_of_employees')
      }
    },
    category7: {
      pattern1: {
        method: i18n.t('b_register_product.method_calculated_from_a_passenger_kilometer')
      },
      pattern2: {
        method: i18n.t('b_register_product.method_calculated_by_fuel_method')
      },
      pattern3: {
        method: i18n.t('b_register_product.method_calculated_by_fuel_efficiency_method')
      },
      pattern4: {
        method: i18n.t('b_register_product.method_calculated_from_the_payment_amount')
      },
      pattern5: {
        method: i18n.t('b_register_product.method_calculated_from_the_number_of_employees_and_business_days')
      },
    },
    category9: {
      pattern1: {
        method: i18n.t('b_register_product.method_transportation'),
        sub_method: i18n.t('b_register_product.method_calculated_by_fuel_method_and_shipping_fee')
      },
      pattern2: {
        method: i18n.t('b_register_product.method_transportation'),
        sub_method: i18n.t('b_register_product.method_calculated_by_the_tkm_method')
      },
      pattern3: {
        method: i18n.t('b_register_product.method_transportation'),
        sub_method: i18n.t('b_register_product.method_calculated_by_fuel_efficiency_method'),
      },
      pattern4: {
        method: i18n.t('b_register_product.method_base'),
        sub_method: i18n.t('b_register_product.method_fuel')
      },
      pattern5: {
        method: i18n.t('b_register_product.method_base'),
        sub_method: i18n.t('b_register_product.method_electricity')
      },
    },
    category11: {
      pattern1: {
        method: i18n.t('b_register_product.method_calculated_from_the_direct_use_stage'),
        sub_method: i18n.t('b_register_product.method_energy_use_products'),
      },
      pattern2: {
        method: i18n.t('b_register_product.method_calculated_from_the_direct_use_stage'),
        sub_method: i18n.t('b_register_product.method_fuel_feedstock'),
      },
      pattern3: {
        method: i18n.t('b_register_product.method_calculated_from_the_direct_use_stage'),
        sub_method: i18n.t('b_register_product.method_GHG_containing_products'),
      },
      pattern4: {
        method: i18n.t('b_register_product.method_calculated_from_the_emissions_at_the_indirect_use_stage'),
      },
    },
    category15: {
      pattern1: {
        method: i18n.t('b_register_product.method_stock'),
      },
      pattern2: {
        method: i18n.t('b_register_product.method_debt_bond_loan'),
      },
      pattern3: {
        method: i18n.t('b_register_product.method_project_finance'),
      },
      pattern4: {
        method: i18n.t('b_register_product.method_asset_management_customer_service'),
      },
      pattern5: {
        method: i18n.t('setting_pcaf.pulldown_pcaf_method_listed_stocks_bouds'),
      },
      pattern6: {
        method: i18n.t('setting_pcaf.pulldown_pcaf_method_bussiness_loans_unlisted_stocks'),
      },
      pattern7: {
        method: i18n.t('setting_pcaf.pulldown_pcaf_method_project_finance'),
      },
      pattern8: {
        method: i18n.t('setting_pcaf.pulldown_pcaf_method_commercial_estate'),
      },
      pattern9: {
        method: i18n.t('setting_pcaf.pulldown_pcaf_method_home_loan'),
      },
      pattern10: {
        method: i18n.t('setting_pcaf.pulldown_pcaf_method_car_loan'),
      },
      pattern11: {
        method: i18n.t('setting_pcaf.pulldown_pcaf_method_debt'),
      }
    }
  }
}

export const getDataLayer = (scope, category, pattern_id) => {
  let dataLayer = {};
  scope = parseInt(scope)
  category = parseInt(category)
  if (scope === 1) {
    dataLayer = dataScopeLayer['scope' + scope]?.['pattern' + pattern_id]
  } else {
    dataLayer = dataScopeLayer['scope' + scope]?.['category' + category]?.['pattern' + pattern_id]
  }

  return dataLayer
}

export const getScopeName = (scope) => {
  if (scope <= 3) {
    return `${i18n.t('list_submited.table_scope')} ` + scope
  } else {
    return i18n.t('register_data.hyperlink_others')
  }
}

export const getCategoryName = (scope, category) => {
  if (scope <= 3) {
    if (category === 16) {
      return i18n.t('register_data.hyperlink_others');
    }
    if (scope <= 2) {
      return ''
    }
    return `${i18n.t('status.table_category')} ` + category; //table_category
  } else {
    return '';
  }
}

export const handleResizeGrid = (theGrid, listColumn, listWidth, callBack) => {
  let paddingCell = 14;
  listColumn.forEach((column, index) => {
    let width = listWidth[index];

    if (width < column.minWidth) {
      width = column.minWidth;
      if (column.dataMap) {
        width = column.minWidth;
      }
    } else if (width > column.maxWidth) {
      width = column.maxWidth;
    }
    callBack(theGrid, index, width + paddingCell);
  })
}

export const getScopeCateriesNameFromPatternId = (scopeCategoriesList, patternId, typeClassify = {}, typeData = {}) => {
  let tx = '';

  if (typeClassify && typeData && [47,48].includes(patternId)) {
    tx = `_${typeClassify.name}_${typeData.name} `;
  } else if (typeData) {
    tx = `_${typeData.name} `;
  }
  let dataScope = scopeCategoriesList
  dataScope[17].methods = [...oldmethod, ...newPcafMethod]
  let itemName = '';
  dataScope.forEach(item => {
    if (item.row_num_pattern === patternId) {
      itemName = item.text;
      return;
    } else {
      if (item?.methods?.length > 0) {
        item?.methods?.forEach(itemLayer1 => {
          if (itemLayer1.row_num_pattern === patternId) {
            itemName = item.text + '_' + itemLayer1.text + tx;
            return;
          } else {
            if (itemLayer1?.methods?.length > 0) {
              itemLayer1?.methods?.forEach(itemLayer2 => {
                if (itemLayer2.row_num_pattern === patternId) {
                  itemName = item.text + '_' + itemLayer1.text + '_' + itemLayer2.text + tx;
                  return;
                }
              })
            }
          }
        })
      }
    }
  })
  return itemName;
}

export const calcYearFromStartMonthCustome = (startMonth, yearSelected = 2017, isGetNextYear = false) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const MIN_YEAR = 2017
  let yearList = [];
  let lastYear = new Date().getFullYear();
  if (startMonth > currentMonth) {
    lastYear = lastYear - 1;
  }
  let minYear = MIN_YEAR

  // const isCurenrYearBiggerCondition = currentYear + 1 === yearSelected // && monthSelected < startMonth
  // const isCurenrYearSmallerCondition = currentYear > yearSelected
  // const isCurenrYearEqualCondition = currentYear === yearSelected && startMonth < currentMonth
  const isValidStartMonth = startMonth > 1
  if (isValidStartMonth  || isGetNextYear) {
    lastYear += 1;
  }
  // if (monthSelected && monthSelected < startMonth) {
  //   minYear = minYear + 1
  // }
  for (let i = lastYear; i >= minYear; i--) {
    yearList.push({
      text: i18n.t('register_data.year_calendar_select', {year: i}),
      value: i
    });
  }
  return yearList;
}

export const getMonthListCustome = (newYear, startMonth) => {
  let monthList = [];
  let maxMonth = 13
  let minMonth = 1
  const currentDate = new Date();
  let currentYear = currentDate.getFullYear();
  let currentMonth = currentDate.getMonth() + 1;
  if (startMonth > currentMonth) {
    currentYear = currentYear - 1;
  }
  if (currentYear + 1 === newYear) { // compare new year with currentYear
    maxMonth = startMonth;
  }

  if (newYear === 2017) { // min year is 2017
    minMonth = startMonth
  }

  for (let i = minMonth; i < maxMonth; i++) {
    let month = convertMonthCalendar(i);
    monthList.push({
      text: i18n.t('register_data.month_select', {month: month}),
      value: i
    });
  }
  return monthList;
}

export const convertMonthCalendar = (month, isNumber = false) => {
  if(i18n.locale === 'en') {
    const monthsAbbreviation = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    if (month >= 1 && month <= 12) {
      return monthsAbbreviation[month - 1];
    }
  }

  if(isNumber) {
    return Number(month);
  }
  return month;
}

export const getFirstMonthByOrder = (moment, startMonth, order, isHalfYear = false) => {
  let addMonth = 0;
  switch (Number(order)) {
    case 1:
      addMonth = 0;
      break;
    case 2:
      addMonth = 3;
      break;
    case 3:
      addMonth = 6;
      break;
    default:
      addMonth = 9;
      break;
  }
  if (isHalfYear) {
    switch (Number(order)) {
      case 1:
        addMonth = 0;
        break;
      case 2:
        addMonth = 6;
        break;
      default:
        break;
    }
  }
  return moment(startMonth, 'M').add(addMonth, 'months').format('M');
}
export const calculateEndDate = (date, periodType) => {
  if (!periodType) return;
  switch (periodType) {
    case PERIOD_DASHBOARD.PERIOD_YEAR:
      date.setFullYear(date.getFullYear() + 1);
      date.setMonth(date.getMonth() - 1);
      break;
    case PERIOD_DASHBOARD.PERIOD_HALF_YEAR:
      date.setMonth(date.getMonth() + 5);
      break;
    case PERIOD_DASHBOARD.PERIOD_QUARTER_YEAR:
      date.setMonth(date.getMonth() + 2);
      break;
    case PERIOD_DASHBOARD.PERIOD_MONTH_YEAR:
      date.setMonth(date.getMonth());
      break;
    default:
      throw new Error('Invalid period type');
  }
  const endMonth = date.getMonth() + 1;
  const endYear = date.getFullYear();
  return [endMonth, endYear];
}
export const getPeriodFromMonthYear = (moment, startMonth, month, year) => {
  const yearStart =  startMonth > month && year - 1 > 2016 ? year - 1 : year;
  const monthStart =  year - 1 > 2016 ? startMonth : '01';

  const formatDate = moment(`${yearStart}/${monthStart}`, 'YYYY/MM');

  return `${formatDate.format('YYYY/MM')} - ${formatDate.add(11, 'months').format('YYYY/MM')}`;
}

export const preparePhysicalList = (type, startMonth) => {
  let currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  if (startMonth && startMonth > currentMonth) {
    currentYear = currentYear - 1;
  }
  let dataList = [];
  for (let i = currentYear; i >= 2017; i--) {
    if (type === 'year') {
      dataList.push({
        value: i,
        name: i18n.t('register_data.year_calendar_select', {year: i}),
      });
    }
    if (type === 'half-year') {
      dataList.push({
        value: `1-${i}`,
        name: `${i} ${i18n.t('dashboard_main.label_first_half_of_fiscal_year')}`,
      });
      dataList.push({
        value: `2-${i}`,
        name: `${i} ${i18n.t('dashboard_main.label_end_half_chart_title')}`,
      });
    } else if (type === 'quarter-year') {
      dataList.push({
        value: `1-${i}`,
        name: `${i} 1Q`,
      });
      dataList.push({
        value: `2-${i}`,
        name: `${i} 2Q`,
      });
      dataList.push({
        value: `3-${i}`,
        name: `${i} 3Q`,
      });
      dataList.push({
        value: `4-${i}`,
        name: `${i} 4Q`,
      });
    }
  }
  return dataList;
}

export const prepareBranchData = (res) => {
  let organizationalList = [];
  let company_name = [];
  let business_name = [];
  let country = [];
  let layer_3 = [];
  let layer_4 = [];
  let layer_5 = [];
  let layer_6 = [];
  let dataIdSort = [];
  let dataSort = [];
  let filterPatternList = [];
  let company_name_obj = {};
  let organizationalList_obj = {};
  let business_name_obj = {};
  let country_obj = {};
  let layer_3_obj = {};
  let layer_4_obj = {};
  let layer_5_obj = {};
  let layer_6_obj = {};
  let companyBranchIds = [];
  let organizationalObj = {};

  res.data.forEach((organizational) => {
    organizationalList.push({
      key: organizational.id,
      value: organizational.organizational_division,
      branch_ids: organizational.branch_ids,
    });
    organizationalObj[organizational.id] = organizational.organizational_division;
    organizational.child?.forEach((company) => {
      companyBranchIds.push({
        organizational_division: organizational.organizational_division,
        company_name: company.company_name,
        branch_ids: company.branch_ids,
        organizational_division_id: organizational.id,
      });
      company?.child.forEach((business) => {
        if (business?.child === undefined) {
          dataIdSort.push(business.id);
          dataSort.push(business);
        }
        business?.child?.forEach((country) => {
          if (country?.child === undefined) {
            dataIdSort.push(country.id);
            dataSort.push(country);
          }
          country?.child?.forEach((layer3Item) => {
            if (layer3Item?.child === undefined) {
              dataIdSort.push(layer3Item.id);
              dataSort.push(layer3Item);
            }
            layer3Item?.child?.forEach((layer4Item) => {
              if (layer4Item?.child === undefined) {
                dataIdSort.push(layer4Item.id);
                dataSort.push(layer4Item);
              }
              layer4Item?.child?.forEach((layer5Item) => {
                if (layer5Item?.child === undefined) {
                  dataIdSort.push(layer5Item.id);
                  dataSort.push(layer5Item);
                }
                layer5Item?.child?.forEach((layer6Item) => {
                  if (layer6Item?.child === undefined) {
                    dataIdSort.push(layer6Item.id);
                    dataSort.push(layer6Item);
                  }
                  layer6Item?.child?.forEach((item) => {
                    dataIdSort.push(item.id);
                    dataSort.push(item);
                  })
                })
              })
            })
          })
        })
      });
    });
  });
  // sort data
  dataIdSort
    .sort((a, b) => {
      return a - b;
    })
    .forEach((id) => {
      dataSort.forEach((item, key) => {
        if (item.id === id) {
          dataSort.splice(key, 1);
          ['business_name', 'country', 'layer_3', 'layer_4', 'layer_5', 'layer_6'].forEach((key) => {
            item[key] = item[key] === null ? i18n.t("register_data.checkbox_blank") : item[key]
          })
          filterPatternList.push({...item, organizational_division_value: organizationalObj[item.organizational_division]});
          const existCompanyIndex = company_name.findIndex((company) => company.value === item.company_name);
          company_name_obj[item.company_name] = item.company_name;
          if (existCompanyIndex < 0) {
            company_name.push({
              organizational_division: item.organizational_division,
              name: item.company_name,
              value: item.company_name,
              status: item.status,
            });
          } else {
            if (item.status === true) {
              company_name[existCompanyIndex].status = true;
            }
          }

          if (item.business_name) {
            const existBusinessIndex = business_name.findIndex((business) => business.value === item.business_name);
            business_name_obj[item.business_name] = item.business_name
            if (existBusinessIndex < 0) {
              business_name.push({
                key: item.business_name,
                value: item.business_name,
                name: item.business_name,
                company_name: item.company_name,
                organizational_division: item.organizational_division,
                status: item.status,
              });
            } else {
              if (item.status === true) {
                business_name[existBusinessIndex].status = true;
              }
            }
          }

          if (item.country) {
            const existCountryIndex = country.findIndex((country) => country.value === item.country);
            country_obj[item.country] = item.country
            if (existCountryIndex < 0) {
              country.push({
                key: item.country,
                value: item.country,
                name: item.country,
                organizational_division: item.organizational_division,
                company_name: item.company_name,
                business_name: item.business_name,
                layer3: item.layer_3,
                layer4: item.layer_4,
                layer5: item.layer_5,
                layer6: item.layer_6,
                status: item.status,
              });
            } else {
              if (item.status === true) {
                country[existCountryIndex].status = true;
              }
            }
          }

          if (item.layer_3) {
            const existLayer3Index = layer_3.findIndex((layer3) => layer3.value === item.layer_3);
            layer_3_obj[item.layer_3] = item.layer_3
            if (existLayer3Index < 0) {
              layer_3.push({
                key: item.layer_3,
                value: item.layer_3,
                name: item.layer_3,
                organizational_division: item.organizational_division,
                company_name: item.company_name,
                business_name: item.business_name,
                layer3: item.layer_3,
                layer4: item.layer_4,
                layer5: item.layer_5,
                layer6: item.layer_6,
                status: item.status,
              });
            } else {
              if (item.status === true) {
                layer_3[existLayer3Index].status = true;
              }
            }
          }

          if (item.layer_4) {
            const existLayer4Index = layer_4.findIndex((layer4) => layer4.value === item.layer_4);
            layer_4_obj[item.layer_4] = item.layer_4
            if (existLayer4Index < 0) {
              layer_4.push({
                key: item.layer_4,
                value: item.layer_4,
                name: item.layer_4,
                organizational_division: item.organizational_division,
                company_name: item.company_name,
                business_name: item.business_name,
                layer3: item.layer_3,
                layer4: item.layer_4,
                layer5: item.layer_5,
                layer6: item.layer_6,
                status: item.status,
              });
            } else {
              if (item.status === true) {
                layer_4[existLayer4Index].status = true;
              }
            }
          }

          if (item.layer_5) {
            const existLayer5Index = layer_5.findIndex((layer5) => layer5.value === item.layer_5);
            layer_5_obj[item.layer_5] = item.layer_5
            if (existLayer5Index < 0) {
              layer_5.push({
                key: item.layer_5,
                value: item.layer_5,
                name: item.layer_5,
                organizational_division: item.organizational_division,
                company_name: item.company_name,
                business_name: item.business_name,
                layer3: item.layer_3,
                layer4: item.layer_4,
                layer5: item.layer_5,
                layer6: item.layer_6,
                status: item.status,
              });
            } else {
              if (item.status === true) {
                layer_5[existLayer5Index].status = true;
              }
            }
          }

          if (item.layer_6) {
            const existLayer6Index = layer_6.findIndex((layer6) => layer6.value === item.layer_6);
            layer_6_obj[item.layer_6] = item.layer_6
            if (existLayer6Index < 0) {
              layer_6.push({
                key: item.layer_6,
                value: item.layer_6,
                name: item.layer_6,
                organizational_division: item.organizational_division,
                company_name: item.company_name,
                business_name: item.business_name,
                layer3: item.layer_3,
                layer4: item.layer_4,
                layer5: item.layer_5,
                layer6: item.layer_6,
                status: item.status,
              });
            } else {
              if (item.status === true) {
                layer_6[existLayer6Index].status = true;
              }
            }
          }
        }
      });
    });

  business_name = moveElementToLastPosition(business_name);
  country = moveElementToLastPosition(country);
  layer_3 = moveElementToLastPosition(layer_3);
  layer_4 = moveElementToLastPosition(layer_4);
  layer_5 = moveElementToLastPosition(layer_5);
  layer_6 = moveElementToLastPosition(layer_6);
  organizationalList = checkOrganizationalDivisionDisabled(organizationalList, filterPatternList);
  organizationalList.forEach(item => {
    organizationalList_obj[item.value] = item
  })
  const organizational_division = organizationalList;
  return { business_name, country, layer_3, layer_4,  layer_5,  layer_6, company_name, organizational_division, filterPatternList, companyBranchIds }
}

function moveElementToLastPosition(arr) {
  const fromIndex = arr.findIndex((item) => item.key === i18n.t("register_data.checkbox_blank"))
  if (fromIndex >= 0) {
    const arrLength = arr.length
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(arrLength - 1, 0, element);
  }
  return arr
}

const checkOrganizationalDivisionDisabled = (organizationalList, filterPatternList) => {
  let compareOrganizationalList = [];
  organizationalList.forEach(organiDiv => {
    const isDisabled = filterPatternList.filter(item => item.organizational_division === organiDiv.key).every(item => item.status === false);
    compareOrganizationalList.push({
      key: organiDiv.key,
      value: organiDiv.value,
      status: isDisabled ? false : true,
      branch_ids: organiDiv.branch_ids,
    })
  })
  return compareOrganizationalList;
}

export const findRowNumPattern = (scopeCategoriesData, scope, categories, method, methodLayer2) => {
  const foundItem = scopeCategoriesData.find(item => {
    return item.scope === scope && item.categories === categories;
  });

  if (foundItem) {
    if (foundItem.methods) {
      for (let subItem of foundItem.methods) {
        if (subItem.method === method) {
          return subItem.row_num_pattern;
        }
        if(subItem.methods && methodLayer2) {
          for (let subSubItem of subItem.methods) {
            if (subSubItem.methodLayer2 === methodLayer2) {
              return subSubItem.row_num_pattern;
            }
          }
        }
      }
    } else {
      return foundItem.row_num_pattern;
    }
  }

  return null;
};
export const isHideColumnEmissionPcaf = (method, type_data) => {
  const { categoryIdSelect, dataTypeIdSelect } = type_data;
  
  const isCategoryMatch = [1, 2].includes(categoryIdSelect);
  const isDataTypeMatch = [1, 2].includes(dataTypeIdSelect);

  if ([1, 2].includes(method) && isCategoryMatch && isDataTypeMatch) {
    return true;
  }

  if (method === 3 && isDataTypeMatch) {
    return true;
  }

  return false;
}

export const isHideColValueNumber = (method, type_data) => {
  const { dataTypeIdSelect } = type_data;

  if (
    ([4, 5].includes(method) && [7, 8].includes(dataTypeIdSelect)) ||
    (method === 6 && dataTypeIdSelect === 12)
  ) {
    return true;
  }

  return false;
}
