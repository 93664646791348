import request from './base/request';

const WORK_FLOW_DATA = '/workflow-data/';
const NOTIFICATION = 'notification'

async function getNotificationApi() {
    return await request.get(`${WORK_FLOW_DATA}${NOTIFICATION}`);
}

async function setNotificationApi(params) {
  return await request.put(`${WORK_FLOW_DATA}${NOTIFICATION}`, params);
}

export default {
  getNotificationApi,
  setNotificationApi
};
