import Vue from 'vue';
const clickHandler = 'ontouchstart' in document.documentElement ? 'touchstart' : 'click';
Vue.directive('click-outside', {
  bind() {
    if(!this) return
    this.event = (event) => this.vm.$emit(this.expression, event)
    this.element.addEventListener(clickHandler, this.stopProp)
    document.body.addEventListener(clickHandler, this.event)
  },
  unbind() {
    if(!this) return
    this.element.removeEventListener(clickHandler, this.stopProp)
    document.body.removeEventListener(clickHandler, this.event)
  },

  stopProp(event) { event.stopPropagation() }
})
