import { defaultEmissionByCategory } from '@/constants/dashboard';
import { DASHBOARD_TYPES } from '@/constants/dashboard';
import { isInt } from '@/filters/number';
import { numberFormat } from '@/filters/number';
import moment from 'moment';

export const prepareChartDataEmissionByPeriodWithYearly = (data) => {
  return Array.from(data).map((item) => {
    return {
      time_range:
        moment(item.start_at, 'YYYY-M').format('YYYY/M') + '-' + moment(item.end_at, 'YYYY-M').format('YYYY/M'),
      start_at: moment(item.start_at, 'YYYY-M').format('YYYY/M'),
      end_at: moment(item.end_at, 'YYYY-M').format('YYYY/M'),
      emission_scope1: item.emission_scope1,
      emission_scope2: item.emission_scope2,
      emission_scope3: item.emission_scope3,
      original: item,
    };
  });
};

export const prepareChartDataEmissionByPeriodWithMonthly = (data) => {
  return Array.from(data).map((item) => {
    return {
      time_range: `${item.year}/${numberFormat(item.month)}`,
      emission_scope1: item.emission_scope1,
      emission_scope2: item.emission_scope2,
      emission_scope3: item.emission_scope3,
      original: item,
    };
  });
};

export const prepareChartDataEmissionByPeriodDetail = (data) => {
  const summaryEmission = Number(data.emission_scope1) + Number(data.emission_scope2) + Number(data.emission_scope3);
  const periodEmissionScope1 = Number(((Number(data.emission_scope1) / summaryEmission) * 100).toFixed(1));
  const periodEmissionScope2 = Number(((Number(data.emission_scope2) / summaryEmission) * 100).toFixed(1));
  const periodEmissionScope3 = Number((100 - periodEmissionScope1 - periodEmissionScope2).toFixed(1));

  return {
    ...data,
    emission_scope1: isInt(Number(data.emission_scope1))
      ? Number(data.emission_scope1)
      : Number(Number(data.emission_scope1).toFixed(2)),
    emission_scope2: isInt(Number(data.emission_scope2))
      ? Number(data.emission_scope2)
      : Number(Number(data.emission_scope2).toFixed(2)),
    emission_scope3: isInt(Number(data.emission_scope3))
      ? Number(data.emission_scope3)
      : Number(Number(data.emission_scope3).toFixed(2)),

    period_emission_scope1: periodEmissionScope1,
    period_emission_scope2: periodEmissionScope2,
    period_emission_scope3: periodEmissionScope3,

    rate_all:
      data.rate_all != null
        ? isInt(Number(data.rate_all))
          ? Number(data.rate_all)
          : Number(Number(data.rate_all).toFixed(2))
        : null,
    rate_last_year:
      data.rate_last_year != null
        ? isInt(Number(data.rate_last_year))
          ? Number(data.rate_last_year)
          : Number(Number(data.rate_last_year).toFixed(2))
        : null,

    rate_scope_1:
      data.rate_scope_1 != null
        ? isInt(Number(data.rate_scope_1))
          ? Number(data.rate_scope_1)
          : Number(Number(data.rate_scope_1).toFixed(2))
        : null,
    rate_scope_2:
      data.rate_scope_2 != null
        ? isInt(Number(data.rate_scope_2))
          ? Number(data.rate_scope_2)
          : Number(Number(data.rate_scope_2).toFixed(2))
        : null,
    rate_scope_3:
      data.rate_scope_2 != null
        ? isInt(Number(data.rate_scope_3))
          ? Number(data.rate_scope_3)
          : Number(Number(data.rate_scope_3).toFixed(2))
        : null,

    total_emission: isInt(Number(data.total_emission))
      ? Number(data.total_emission)
      : Number(Number(data.total_emission).toFixed(2)),
  };
};

export const prepareChartDataEmissionByCategoryWith = (data, type) => {
  const isTypeYearly = type === DASHBOARD_TYPES.duration;

  const result = defaultEmissionByCategory;
  const values = [];

  // scope 1
  const scope1Input = data.scope_1; // Object
  values.push(isTypeYearly ? scope1Input.total_duration : scope1Input.total_month);

  result.scope1 = {
    ...result.scope1,
    [scope1Input.row_num]: {
      ...result.scope1[scope1Input.row_num],
      value: isTypeYearly ? scope1Input.total_duration : scope1Input.total_month,
      total_duration: scope1Input.total_duration,
      total_month: scope1Input.total_month,
      total: scope1Input.total,
      default: false,
      original: scope1Input,
    },
  };

  // scope 2
  const scope2Input = data.scope_2; // Object
  values.push(isTypeYearly ? scope2Input.total_duration : scope2Input.total_month);

  result.scope2 = {
    ...result.scope2,
    [scope2Input.row_num]: {
      ...result.scope2[scope2Input.row_num],
      value: isTypeYearly ? scope2Input.total_duration : scope2Input.total_month,
      total_duration: scope2Input.total_duration,
      total_month: scope2Input.total_month,
      total: scope2Input.total,
      default: false,
      original: scope2Input,
    },
  };

  // scope 3
  const scope3Input = data.scope_3; // Array
  Array.from(scope3Input).forEach((v) => {
    const rowNum = v.row_num;
    values.push(isTypeYearly ? v.total_duration : v.total_month);

    result.scope3 = {
      ...result.scope3,
      [rowNum]: {
        ...result.scope3[rowNum],
        value: isTypeYearly ? v.total_duration : v.total_month,
        total_duration: v.total_duration,
        total_month: v.total_month,
        total: v.total,
        default: false,
        original: v,
      },
    };
  });

  // scope 4
  const scope4Input = data.scope_4; // Object
  values.push(isTypeYearly ? scope4Input.total_duration : scope4Input.total_month);

  result.scope4 = {
    ...result.scope4,
    [scope4Input.row_num]: {
      ...result.scope4[scope4Input.row_num],
      value: isTypeYearly ? scope4Input.total_duration : scope4Input.total_month,
      total_duration: scope4Input.total_duration,
      total_month: scope4Input.total_month,
      total: scope4Input.total,
      default: false,
      original: scope4Input,
    },
  };

  return [result, Math.max(...values), Math.min(...values), values];
};

export const prepareDurationByCategoryWithYearly = (data) => {
  return Array.from(data).map((item) => {
    return {
      value: item.id,
      name: `${moment(item.start_at, 'YYYY-M').format('YYYY/M')} - ${moment(item.end_at, 'YYYY-M').format('YYYY/M')}`,
      is_selected: item.is_selected,
      original: item,
      start_at: moment(item.start_at, 'YYYY-M').format('YYYY/M'),
      end_at: moment(item.end_at, 'YYYY-M').format('YYYY/M'),
    };
  });
};

export const prepareDurationByCategoryWithMonthly = (data) => {
  let tempData = {};

  Array.from(data).forEach((item) => {
    const startAt = moment(item.start_at, 'YYYY-M');
    const endAt = moment(item.end_at, 'YYYY-M');

    const range = moment.range(startAt, endAt);

    for (const month of range.by('month')) {
      const y = month.format('YYYY');
      const m = month.format('M');
      tempData[y] = {
        ...tempData[y],
        [m]: m,
      };
    }
  });

  const result = [];

  Object.keys(tempData).forEach((year) => {
    const tempMonth = tempData[year];

    Object.keys(tempMonth)
      .sort()
      .forEach((month) => {
        result.push({
          month,
          year,
        });
      });
  });

  return result;
};

export const maxValueEmissionsByCategoryWithYearly = (data) => {
  const values = [];
  const scope1 = data.scope1;
  const scope1Props = Object.getOwnPropertyNames(scope1);
  Array.from(scope1Props).forEach((v) => {
    if (v !== '__ob__') {
      values.push(scope1[v].total_duration ? scope1[v].total_duration : scope1[v].total_month);
    }
  });

  const scope2 = data.scope2;
  const scope2Props = Object.getOwnPropertyNames(scope2);
  Array.from(scope2Props).forEach((v) => {
    if (v !== '__ob__') {
      values.push(scope2[v].total_duration ? scope2[v].total_duration : scope2[v].total_month);
    }
  });

  const scope3 = data.scope3;
  const scope3Props = Object.getOwnPropertyNames(scope3);

  Array.from(scope3Props).forEach((v) => {
    if (v !== '__ob__') {
      values.push(scope3[v].total_duration ? scope3[v].total_duration : scope3[v].total_month);
    }
  });

  const scope4 = data.scope4;
  const scope4Props = Object.getOwnPropertyNames(scope4);
  Array.from(scope4Props).forEach((v) => {
    if (v !== '__ob__') {
      values.push(scope4[v].total_duration ? scope4[v].total_duration : scope4[v].total_month);
    }
  });
  return [Math.max(...values), Math.min(...values), values];
};
