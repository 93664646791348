import {prepareMasterOrCustomizeDB} from '@/concerns/utils/master-or-customize-db'

const defaultState = () => {
  return {
    scope: 1,
    category: 1,
    method: 1,
    month: 1,
    duration: 1,
    durationValue: {
      start_at: null,
      end_at: null,
    },
    listService: [],
    energyTypes: [
      {
        id: 1,
        name: 'ガソリン',
        unit: 'KL',
        wsu_value: 2.5,
        wsu_unit: 't-CO2/KL',
        wsu_source: '算定・報告・公表制度における排出係数　【燃料法】',
      },
      {
        id: 2,
        name: '経由',
        unit: 'KL',
        wsu_value: 2,
        wsu_unit: 't-CO2/KL',
        wsu_source: '算定・報告・公表制度における排出係数　【燃料法】',
      },
      {
        id: 3,
        name: '都市ガス',
        unit: '千㎥',
        wsu_value: 3,
        wsu_unit: 't-CO2/千㎥',
        wsu_source: '算定・報告・公表制度における排出係数　【燃料法】',
      },
      {
        id: 4,
        name: 'type test',
        unit: '千㎥',
        wsu_value: 4,
        wsu_unit: 't-CO2/千㎥',
        wsu_source: '算定・報告・公表制度における排出係数　【燃料法】',
      },
    ],
    data: [],
    companyList: [],
    isUpdateData: false,
    totalEmissions: 0,
    listBusinessName: [],
    listCompanyName: [],
    listType: [],
    dbCustomizes: [],
    totalDurationEmissions: 0,
    isError: false,
    errorMess: [],
    errorFilterData: '',
    latestRecord: {},
    yearSelected: null,
    idCustomizes: [],
    listDuration: [],
    startMonth: null,
    isUpdated: false,
    undoRedoClick: '',
    undoStack: null,
    totalData: null,
    isClearUndoStack: false,
    totalTreatment: {
      incinerator: null,
      landfill: null,
      recycling: null,
      unknown: null,
    },
    listBranch: [],
    watchDeleted: {status: false, ids: []},
    allowAddNew: true,
    undoRedoStackData: {},
    isLoading: false,
    isFullScreen: false,
    listOfEditedCells: [],
    isShowPopup: false,
    methodLayer2: null,
    orderNumberDontHavePermissions: [],
    layerFilter: [],
    newScope: 1,
    isChangedDurationSuccess: false,
    checkIvalueDurationSuccess: false,
    listBranchObj: {},
    certifications: []
  }
}

const state = defaultState();

export const getters = {
  getUrlFromScopeCategory(state) {
    switch (state.scope) {
      case 1:
        return `pattern-s1/${state.method}`;

      default:
        return `pattern-s1/${state.method}`;
    }
  },

  getDbCustomizeByItemName(state) {
    let newDbCus = {}

    state.dbCustomizes.forEach(item => {
      newDbCus[item.item_name] = !newDbCus[item.item_name] ? {...item} : newDbCus[item.item_name];
    });
    return newDbCus
  },

  getDbCustomizeOrDbMasterByItemName(state) {
    let result = {}

    prepareMasterOrCustomizeDB(state.dbCustomizes).forEach((item) => {
      if (!result[item.item_name]) {
        result[item.item_name] = {...item}
      }
    });

    return result
  },

  getDbCustomizeByIdSource(state) {
    let newDbCus = {}

    state.dbCustomizes.forEach(item => {
      newDbCus[item.id] = !newDbCus[item.id] ? {...item} : newDbCus[item.id];
    });
    return newDbCus
  },

  getDbCustomizeOrDbMasterById(state) {
    let result = {}

    prepareMasterOrCustomizeDB(state.dbCustomizes).forEach((item) => {
      if (!result[item.id]) {
        result[item.id] = {...item}
      }
    })

    return result
  },

  dbCustomizesOrDbMasters(state) {
    return prepareMasterOrCustomizeDB(state.dbCustomizes)
  },

  getDbCustomizeOrDbMasterByWsuSource(state) {
    let result = {}

    prepareMasterOrCustomizeDB(state.dbCustomizes).forEach((item) => {
      if (!result[item.source]) {
        result[item.source] = {...item}
      }
    });

    return result
  },

  getCertificationName(state) {
    return state.certifications
  },

  getCertificationNameObj(state) {
    const data = {}
    state.certifications.forEach(certifi => {
      if(!data[certifi.name]) {
        data[certifi.name] = {...certifi }
      }
    })
    return data
  },

  getCertificationType(state) {
    const data = {}
    state.certifications.forEach(certifi => {
      certifi?.child.forEach(itemType => {
        if(!data[itemType.name]) {
          data[itemType.name] = {
            ...itemType,
            parents: [ certifi.id ]
          }
        } else {
          data[itemType.name].parents.push(certifi.id)
        }
      })
    })
    return Object.values(data)
  },

  getCertificationTypeObj(state) {
    const data = {}
    state.certifications.forEach(certifi => {
      certifi?.child.forEach(itemType => {
        if(!data[itemType.name]) {
          data[itemType.name] = {
            ...itemType,
            parents: [ certifi.id ]
          }
        } else {
          data[itemType.name].parents.push(certifi.id)
        }
      })
    })
    return data
  },

  getCertificationTypeCustome(state) {
    const data = {}
    state.certifications.forEach(certifi => {
      certifi?.child.forEach(itemType => {
        if(!data[certifi.name + itemType.name]) {
          data[certifi.name + itemType.name] = {
            ...itemType,
            fullname: certifi.name + itemType.name,
            type: itemType.name,
            parents: [ certifi.id ]
          }
        }
      })
    })
    return data
  },

  getCertificationTypeCustomeId(state) {
    const data = {}
    state.certifications.forEach(certifi => {
      certifi?.child.forEach(itemType => {
        if(!data[certifi.id + itemType.id]) {
          data[certifi.id + itemType.id] = {
            ...itemType,
            fullname: certifi.name + itemType.name,
            parents: [ certifi.id ]
          }
        }
      })
    })
    return data
  },
};

export const mutations = {
  updateCheckIvalueDurationSuccess(state,checkIvalueDurationSuccess){
    state.checkIvalueDurationSuccess = checkIvalueDurationSuccess;
  },
  updateChangedDurationSuccess(state, isChangedDurationSuccess){
    state.isChangedDurationSuccess = isChangedDurationSuccess
  },
  updateScope(state, newScope) {
    state.newScope = newScope;
  },
  updateCategory(state, newCategory) {
    state.category = newCategory;
  },
  updateMethod(state, newMethod) {
    state.method = newMethod;
  },
  updateMonth(state, newMonth) {
    state.month = newMonth;
  },
  updateServices(state, newService) {
    state.listService = newService;
  },
  updateEnergyType(state, energyType) {
    state.energyTypes = energyType;
  },
  updateDataList(state, data) {
    state.data = [];
    state.data = data;
  },
  updateCompany(state, data) {
    state.companyList = data;
  },
  updateIsUpdateData(state, data) {
    state.isUpdateData = data;
  },
  updateEmission(state, data) {
    state.totalEmissions = data < 0 ? 0 : data;
  },
  updateBusinessName(state, data) {
    state.listBusinessName = data;
  },
  updateCompanyName(state, data) {
    state.listCompanyName = data;
  },
  updateType(state, data) {
    state.listType = data;
  },
  updateDuration(state, value) {
    state.duration = value;
  },
  updateDbCustom(state, value) {
    state.dbCustomizes = value;
  },
  updateDurationEmission(state, data) {
    state.totalDurationEmissions = data < 0 ? 0 : data;
  },
  updateDurationValue(state, value) {
    state.durationValue = value;
  },
  updateError(state, value) {
    state.isError = value;
  },
  updateErrorMess(state, value) {
    state.errorMess = value;
  },
  updateErrorFilterDataMess(state, value) {
    state.errorFilterData = value;
  },
  updateLatestRecord(state, value) {
    state.latestRecord = value;
  },
  updateYearSelected(state, data) {
    state.yearSelected = data;
  },
  updateListIdDbCustomize(state, data) {
    state.idCustomizes = data;
  },
  updateListDuration(state, data) {
    state.listDuration = data;
  },
  updateStartMonth(state, data) {
    state.startMonth = data;
  },
  updateIsUpdated(state, data) {
    state.isUpdated = data;
  },
  updateUndoRedoClick(state, step) {
    state.undoRedoClick = step;
  },
  updateUndoStack(state, stack) {
    state.undoStack = stack;
  },
  updateTotalAfterCreate(state, totalAdd) {
    state.totalEmissions = state.totalEmissions + totalAdd;
    state.totalDurationEmissions = state.totalDurationEmissions + totalAdd;
  },
  updateRecordInsert(state, listRecord) {
    let oldData = state.data.filter((item) => item.id);
    state.data = [...oldData, ...listRecord];
  },
  updateTotalAffterDelete(state, totalNewEmissions) {
    state.totalEmissions = state.totalEmissions - totalNewEmissions < 0 ? 0 : state.totalEmissions - totalNewEmissions;
    state.totalDurationEmissions = state.totalDurationEmissions - totalNewEmissions < 0 ? 0 : state.totalDurationEmissions - totalNewEmissions;
  },
  updateTotalData(state, total) {
    state.totalData = total;
  },
  updateIsClearUndoStack(state, newState) {
    state.isClearUndoStack = newState;
  },
  updateTotalTreatment(state, newState) {
    state.totalTreatment = newState;
  },
  addTotalTreatment(state, newState) {
    state.totalTreatment.incinerator = Number(state.totalTreatment.incinerator) + Number(newState.incinerator);
    state.totalTreatment.landfill = Number(state.totalTreatment.landfill) + Number(newState.landfill);
    state.totalTreatment.recycling = Number(state.totalTreatment.recycling) + Number(newState.recycling);
    state.totalTreatment.unknown = Number(state.totalTreatment.unknown) + Number(newState.unknown);
  },
  updateListBranch(state, newState) {
    state.listBranch = newState;
  },
  updateTotalTreatmentAffterDelete(state, totalDelete) {
    const incinerator = Number(state.totalTreatment.incinerator) - Number(totalDelete.incinerator);
    const landfill = Number(state.totalTreatment.landfill) - Number(totalDelete.landfill);
    const recycling = Number(state.totalTreatment.recycling) - Number(totalDelete.recycling);
    const unknown = Number(state.totalTreatment.unknown) - Number(totalDelete.unknown);

    state.totalTreatment.incinerator = incinerator < 0 ? 0 : incinerator;
    state.totalTreatment.landfill = landfill < 0 ? 0 : landfill;
    state.totalTreatment.recycling = recycling < 0 ? 0 : recycling;
    state.totalTreatment.unknown = unknown < 0 ? 0 : unknown;
  },
  updateStatusDelete(state, newState) {
    state.watchDeleted = {status: newState.status, ids: newState.ids};
  },
  updateAllowAddNew(state, allowAddNew) {
    state.allowAddNew = allowAddNew;
  },
  updateUndoRedoStackData(state, data) {
    state.undoRedoStackData = data;
  },
  updateIsLoading(state, data) {
    state.isLoading = data;
  },
  clearUndoRedoStackData(state, data) {
    state.undoRedoStackData = data;
  },
  updateIsFullScreen(state, status) {
    state.isFullScreen = status;
  },
  updateListOfEditedCells(state, data) {
    state.listOfEditedCells.push(...data)
  },
  removeListOfEditedCells(state) {
    state.listOfEditedCells = []
  },
  removeDisableListOfEditedCells(state, list) {
    let newList = []
    newList = state.listOfEditedCells.filter(item => {
      return item.row !== list[0].row
    })

    state.listOfEditedCells = newList
  },
  updateISshowPopup(state, data) {
    state.isShowPopup = data
  },
  updateMethodLayer2(state, newMethod) {
    state.methodLayer2 = newMethod;
  },
  updateOrderNumberDontHavePermissions(state, listOrderNumberDontHavePermissions) {
    state.orderNumberDontHavePermissions = listOrderNumberDontHavePermissions
  },
  updateLayerFilter(state, data) {
    state.layerFilter = data
  },
  updateListBranchObj(state, newState) {
    state.listBranchObj = newState;
  },
  updateCertifications(state, newState) {
    state.certifications = newState;
  },
  clearData(state) {
    Object.assign(state, defaultState());
  },
};

export const actions = {
  actionCheckIvalueDurationSuccess({commit},checkIvalueDurationSuccess){
    commit("updateCheckIvalueDurationSuccess",checkIvalueDurationSuccess)
  },
  actionUpdateIsChangedDurationSuccess({commit},isChangedDurationSuccess){
    commit("updateChangedDurationSuccess",isChangedDurationSuccess)
  },
  updateDataScope({commit}, newScope) {
    commit('updateScope', newScope);
  },
  updateDataMethod({commit}, newMethod) {
    commit('updateMethod', newMethod);
  },
  updateDataCategory({commit}, newCategory) {
    commit('updateCategory', newCategory);
  },
  updateDataMonth({commit}, newMonth) {
    commit('updateMonth', newMonth);
  },
  updateDataServices({commit}, newService) {
    commit('updateServices', newService);
  },
  updateDataEnergyType({commit}, EnergyType) {
    commit('updateEnergyType', EnergyType);
  },
  updateDataList({commit}, data) {
    commit('updateDataList', data);
  },
  updateCompanyList({commit}, data) {
    commit('updateCompany', data);
  },
  updateStatusIsUpdateData({commit}, data) {
    commit('updateIsUpdateData', data);
  }, //updateEmission
  updateTotalEmission({commit}, data) {
    commit('updateEmission', data);
  },
  updateListBusinessName({commit}, data) {
    commit('updateBusinessName', data);
  },
  updateListCompanyName({commit}, data) {
    commit('updateCompanyName', data);
  },
  updateListType({commit}, data) {
    commit('updateType', data);
  },
  updateDuration({commit}, duration) {
    commit('updateDuration', duration);
  },
  updateListDbCustomize({commit}, data) {
    commit('updateDbCustom', data);
  },
  updateTotalDurationEmission({commit}, data) {
    commit('updateDurationEmission', data);
  },
  updateDurationValue({commit}, durationValue) {
    commit('updateDurationValue', durationValue);
  },
  updateIsErrorStatus({commit}, data) {
    commit('updateError', data);
  },
  updateListErrorMessage({commit}, data) {
    commit('updateErrorMess', data);
  },
  updateListErrorFilterDataMessage({commit}, data) {
    commit('updateErrorFilterDataMess', data);
  },
  updateLatestRecord({commit}, data) {
    commit('updateLatestRecord', data);
  },
  updateYearSelected({commit}, data) {
    commit('updateYearSelected', data);
  },
  updateListIdDbCustomize({commit}, data) {
    commit('updateListIdDbCustomize', data);
  },
  updateListDuration({commit}, data) {
    commit('updateListDuration', data);
  },
  updateStartMonth({commit}, data) {
    commit('updateStartMonth', data);
  },
  updateIsUpdated({commit}, data) {
    commit('updateIsUpdated', data);
  },
  updateUndoRedoClick({commit}, data) {
    commit('updateUndoRedoClick', data);
  },
  updateUndoStack({commit}, data) {
    commit('updateUndoStack', data);
  },
  updateTotalAfterCreate({commit}, data) {
    commit('updateTotalAfterCreate', data);
  },
  updateRecordInsert({commit}, data) {
    commit('updateRecordInsert', data);
  },
  updateTotalAffterDelete({commit}, totalDelete) {
    commit('updateTotalAffterDelete', totalDelete);
  },
  actionUpdateTotalData({commit}, total) {
    commit('updateTotalData', total);
  },
  actionUpdateIsClearUndoStack({commit}, newState) {
    commit('updateIsClearUndoStack', newState);
  },
  actionUpdateTotalTreatment({commit}, newState) {
    commit('updateTotalTreatment', newState);
  },
  actionAddTotalTreatment({commit}, newState) {
    commit('addTotalTreatment', newState);
  },
  actionUpdateListBranch({commit}, newState) {
    commit('updateListBranch', newState);
  },
  actionUpdateTotalTreatmentAffterDelete({commit}, newState) {
    commit('updateTotalTreatmentAffterDelete', newState);
  },
  actionUpdateStatusDelete({commit}, status) {
    commit('updateStatusDelete', status);
  },
  actionAllowAddNew({commit}, allowAddNew) {
    commit('updateAllowAddNew', allowAddNew);
  },
  updateUndoRedoStackData({commit}, data) {
    commit('updateUndoRedoStackData', data);
  },
  actionUpdateIsLoading({commit}, data) {
    commit('updateIsLoading', data);
  },
  actionclearUndoRedoStackData({commit}) {
    commit('clearUndoRedoStackData', {undo: [], redo: []});
  },
  actionUpdateIsFullScreen({commit}, status) {
    commit('updateIsFullScreen', status);
  },
  actionUpdateListOfEditedCells({commit}, data) {
    commit('updateListOfEditedCells', data);
  },
  actionRemoveListOfEditedCells({commit}) {
    commit('removeListOfEditedCells');
  },
  actionRemoveListDisableOfEditedCells({commit}, data) {
    commit('removeDisableListOfEditedCells', data);
  },
  actionShowPopup({commit}, data) {
    commit('updateISshowPopup', data);
  },
  updateDataMethodLayer2({commit}, newMethod) {
    commit('updateMethodLayer2', newMethod);
  },
  updateOrderNumberDontHavePermissions({commit}, listOrderNumberDontHavePermissions) {
    commit('updateOrderNumberDontHavePermissions', listOrderNumberDontHavePermissions)
  },
  actionUpdateLayerFilter({commit}, data) {
    commit('updateLayerFilter', data);
  },
  actionUpdateListBranchObj({commit}, newState) {
    commit('updateListBranchObj', newState);
  },
  actionsUpdateCertifications({commit}, newState) {
    commit('updateCertifications', newState);
  },
  clearData({commit}) {
    commit('clearData');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
