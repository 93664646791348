<template>
    <div class="comment-dialog-data-table" :class="{ disabled: disabledUndoRedoAction() }">
      <div class="comment-data-table">
        <v-btn :class = " !this.totalComment ? 'disabled' : ''" depressed class="button-comment-dialog" @click="handleLogHistory">
          <img class="comment-dialog-img" src="@/assets/images/emissions/button/comment.svg" alt="" />
          <img class="comment-dialog-img-action" src="@/assets/images/emissions/button/comment-active.svg" alt="" />
          <img class="comment-dialog-img-disabled" src="@/assets/images/emissions/button/comment-disabled.svg" alt="" />
          <span class="comment-dialog-text">{{ totalComment }}</span></v-btn
        >
      </div>
    </div>
  </template>
  <script>
  import { ROUTES } from '@/router/constants';
  export default {
    data() {
      return {
        enableInRoute: [
          ROUTES.USER_MANAGEMENT,
          ROUTES.VIEWER_MANAGEMENT,
          ROUTES.FACILITY_MANAGEMENT,
          ROUTES.CREATE_DATA_CUSTOMIZE,
          ROUTES.EMISSIONS + '/' + ROUTES.REGISTER_DATA,
          ROUTES.PRODUCTS + ROUTES.PRODUCTS_NEW_REGISTER,
          ROUTES.PRODUCTS + ROUTES.USER_MANAGEMENT,
          ROUTES.PRODUCTS + ROUTES.VIEWER_MANAGEMENT,
          ROUTES.PRODUCTS + ROUTES.FACILITY_MANAGEMENT,
          ROUTES.PRODUCTS + ROUTES.CREATE_DATA_CUSTOMIZE,
        ],
        enableActionRegisterData: [ROUTES.EMISSIONS + '/' + ROUTES.REGISTER_DATA],
      };
    },
    props: {
      totalComment: {
        type: Number,
        default: 0,
      }
    },
    computed: {
    },
  
    mounted() {
      this.disabledUndoRedoAction();
    },
  
    methods: {
      disabledUndoRedoAction() {
        if (
          this.enableInRoute.includes(this.$route.path) ||
          this.$route.name === 'RegisterData' ||
          this.$route.name === 'NewRegisterData' ||
          this.$route.path === ROUTES.PRODUCTS + '/' + ROUTES.PRODUCTS_NEW_REGISTER
        ) {
          return false;
        }
        return true;
      },
  
      handleLogHistory() {
        this.$emit('onHandleShowCommentHistory');
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  .comment-dialog-data-table {
    // width: 100%;
    justify-content: space-between;
    margin-left: auto;
    display: flex;
    align-items: flex-start;
    .comment-data-table {
      //TODO
      // margin-left: 10px;
    }
  
    .button-comment-dialog {
      box-sizing: border-box;
      padding: 6px 8px;
      height: 28px;
      background: #f7f7f2;
      border: 1px solid rgba(42, 42, 48, 0.1);
      border-radius: 4px;
      flex: none;
      flex-grow: 0;
      min-width: 45px;
      width: 45px;
      .v-btn__content {
        .comment-dialog-img {
          margin-right: 6px;
        }
        .comment-dialog-img-action {
          margin-right: 6px;
          display: none;
        }
        .comment-dialog-img-disabled {
          margin-right: 6px;
          display: none;
        }
        .comment-dialog-text {
          font-style: normal;
          font-weight: 700;
          font-size: 11px;
          line-height: 18px;
          text-align: center;
          letter-spacing: 0.33px;
          color: #404D50;
          max-width: 5.4px;
        }
      }
      &:hover {
        background: #0072a3;
        .v-btn__content {
          .comment-dialog-img {
            display: none;
          }
          .comment-dialog-img-action {
            display: block;
          }
          .comment-dialog-img-disabled {
            display: none;
          }
          .comment-dialog-text {
            color: #ffffff;
          }
        }
      }
    }
    .button-comment-dialog.disabled {
      pointer-events: none;
      background: $monoLight;
      .comment-dialog-text {
        color: $monoMid;
      }
      .comment-dialog-img-disabled {
        display: block;
      }
      .comment-dialog-img {
        display: none;
      }
    }
  
    &.disabled {
      display: none;
    }
  }
  </style>
  