import request from './base/request';
import { prepareMasterOrCustomizeDB } from '@/concerns/utils/master-or-customize-db';
import { DB_TYPE } from '@/constants/dbType';
export var listAllDbServiceLink = [];
export var dbServiceLinkObj = {};
export var dbServiceLinkBySourceName = {};

export const getAllDbServiceLink = () => {
  return request
    .get('/services/list-product')
    .then((res) => {
      listAllDbServiceLink = res.data;
      const allPackagesSource = [];
      listAllDbServiceLink?.forEach((serviceLink) => {
        serviceLink.packages?.forEach((item) => {
          const idCode = `${serviceLink.code}_${item.package_code}`;
          allPackagesSource.push({
            ...item,
            source: item.source,
            numberValue: item.package_used_number,
            item_name: item.package_name,
            product_code: serviceLink.code,
            id: idCode,
            type: DB_TYPE.SERVICE_LINK
          });
        });
      });
      allPackagesSource.forEach((item) => {
        dbServiceLinkBySourceName[item.source] = { ...item, type: DB_TYPE.SERVICE_LINK };
      });
      let result = {};
      prepareMasterOrCustomizeDB(
        allPackagesSource.map((item) => {
          return { ...item, type: DB_TYPE.SERVICE_LINK };
        }),
      ).forEach((item) => {
        if (!result[item.id]) {
          result[item.id] = { ...item };
        }
      });
      dbServiceLinkObj = result;
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const getDetailServiceLink = (body) => {
  const params = { service_link_id: body.service_link_id, product_code: body.product_code };
  return request
    .get('/services/get-detail-service-by-product-code', { params: params })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

export const getServicesLinked = (isCheckedExist) => {
  const params = isCheckedExist ? { is_exist_service_linked: 1 } : {};
  return request
    .get('/services', { params: params })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};
