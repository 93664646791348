import {
  MESSAGE_NOT_NULL,
  MESSAGE_MAX_20_CHARACTOR,
  MESSAGE_IS_NUMBER,
  MESSAGE_POSITIVE_NUMBER
}
  from '@/constants/registerData'
import { create, all } from 'mathjs'; 
/**
 * @param {string|null} valueInput
 * @returns string
 */
export const formatValue = (valueInput) => {
  if (!valueInput && valueInput !== 0) {
    return null;
  }
  return (valueInput || 0).toString().trim().replace(/,/g, '');
};

export const $_helper_isNumberType =(input)=>{
  if (!input && input !== 0) {
    return false;
  }
  if(formatValue(input).includes('-') && formatValue(input).length < 2) {
    return false
  }
  const realNumber = input?.toString()?.trim()?.replace(/,/g, '');
  return /^-?\d*\.?\d*$/.test(realNumber);
}

export const $_helper_isNegativeNumber =(input)=>{
  return $_helper_isNumberType(input) && formatValue(input) < 0;
}

export const validateNumberField = (input) => {
  if (input) {
    if(!$_helper_isNumberType(input)){
      return MESSAGE_IS_NUMBER; 
    }

    //remove check negative number
    // if ($_helper_isNegativeNumber(input)) {
    //   return MESSAGE_POSITIVE_NUMBER;
    // }
    const numberValidate = formatValue(input).includes('-') ? 26 : 25
    if (formatValue(input).length > numberValidate) {
      return MESSAGE_MAX_20_CHARACTOR;
    }
  } else {
    return MESSAGE_NOT_NULL;
  }
  return null;
}

const config = {
  // Default type of number
  // Available options: 'number' (default), 'BigNumber', or 'Fraction'
  number: 'BigNumber',

  // Number of significant digits for BigNumbers
  precision: 50,

}
export const math = create(all, config)

export const formatBigNumber = (value, precision = 2) => {
  const response = math.format(value, {notation: 'fixed', precision: precision});
  return response === '-0.00' ? '0.00' : response;
}

const NA_VALUE = 'N/A';
export const formatEmission25Number = (emission) => {
  const format25Number = formatValue(emission)?.includes('-') ? 26 : 25;
  return formatValue(emission)?.substring(0, format25Number) === NA_VALUE ? null : formatValue(emission)?.substring(0, format25Number)
}

export const fullWidthToHalfWidth = (value) => {
  return value.replace(/[０-９]/g, (match) => {
    return String.fromCharCode(match.charCodeAt(0) - 0xFEE0);
  });
}
