import i18n from '@/lang/i18n';
export const CASBEE = {
  CASBEE: '建物原単位',
  HOME: i18n.t('casbee.option_detached_house'),
  ARCHITECTURE: i18n.t('casbee.option_architecture'),
}

export const HOME = {
  CATEGORY_1: i18n.t('casbee.option_category_1'),
  CATEGORY_11_13: i18n.t('casbee.option_category_11_13'),
}

export const INPUT_TYPE = {
  CHOOSE_CONDITION: i18n.t('casbee.radio_select_condition_formula'),
  ENTER_FREELY: i18n.t('casbee.radio_select_free_input_energy'),
}

export const PURPOSE_USING = {
  APARTMENT: i18n.t('casbee.radio_select_housing_complex'),
  OUTSIDE: i18n.t('casbee.radio_select_collective_housing'),
}

export const DBCUSTOMIZE_TYPE = {
  CASBEE: 0,
}

export const CASBEE_TYPE = {
  HOME: 1,
  HOME_CHOOSE_CONDITION: 2,
  HOME_ENTER_FREELY: 3,
  ARCHITECTURE: 4,
  ARCHITECTURE_APARTMENT_CHOOSE_CONDITION: 5,
  ARCHITECTURE_APARTMENT_ENTER_FREELY: 6,
  ARCHITECTURE_OUTSIDE_CHOOSE_CONDITION: 7,
  ARCHITECTURE_OUTSIDE_ENTER_FREELY: 8,
}