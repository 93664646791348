import { DB_TYPE } from '@/constants/dbType';
export const getDbRelationKeysByType = (type) => {
  switch (type) {
    case DB_TYPE.IDEA:
      return 'db_source_detail_id';
    case DB_TYPE.SERVICE_LINK:
      return 'service_product_package_link_code';
    default:
      return 'db_source_detail_id';
  }
};
