import request from './base/request';
import i18n from '@/lang/i18n.js'

const termsOfUse = (data) => {
  return request
    .get('/terms', data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};
const policy = (data) => {
  return request
    .get('/policy', data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

const validateRequired = (elementList) => {
  let errorLst = [];
  if (elementList.length === 0) {
    return errorLst;
  }
  //let isValid = true;
  for (let i = 0; i < elementList.length; i++) {
    var element = document.getElementById(elementList[i]);
    var errMess = document.getElementById(elementList[i] + '_error');
    //element.removeEventListener('input', listenerEvent.inputCallBack);
    if (element.value === '') {
      errMess.style.display = 'block';
      errMess.innerHTML = i18n.t("login.error_required");
      element.style.border = '1px solid #C24E4B';
      element.parentElement.style.position = 'relative';
      let imgWarning = document.getElementById(elementList[i] + '_img');
      let locationY = 40;
      if (imgWarning) {
        imgWarning.style.display = 'block';
        for (let i = 0; i < element.parentElement.children.length; i++) {
          let inputTag = element.parentElement.children[i];
          if (inputTag.tagName.toLowerCase() == 'input') {
            locationY = inputTag.offsetTop + 12;
          }
        }
        imgWarning.style.top = locationY + 'px';
      }

      //eye
      var eyeIcon = document.getElementById(elementList[i] + '_eye');
      if (eyeIcon != null) {
        listenerEvent.inputChange(elementList[i]);
        eyeIcon.style.display = 'none';
      }
      errorLst.push(elementList[i]);
    } else {
      errMess.style.display = 'none';
      element.style.border = 'none';
      let imgWarning = document.getElementById(elementList[i] + '_img');
      if (imgWarning) {
        imgWarning.style.display = 'none';
      }
    }
  }
  return errorLst.length <= 0;
};

const reLocateDashHeader = (isExpend) => {
  let inspire = document.getElementById('inspire');
  let headFix = document.getElementById('headFix');
  if (headFix == null) {
    return;
  }
  let scrollPos = inspire.getBoundingClientRect();
  if (scrollPos.top <= -163) {
    if (!isExpend) {
      headFix.style.left = '73px';
    } else {
      headFix.style.left = '236px';
    }
  }
};

const listenerEvent = {
  inputChange(elmId) {
    document.getElementById(elmId).addEventListener('input', () => {
      var eyeIcon = document.getElementById(elmId + '_eye');
      var warnIcon = document.getElementById(elmId + '_img');
      var element = document.getElementById(elmId);
      if (element.style.border === 'none') {
        return;
      }
      if (element.value == '') {
        eyeIcon.style.display = 'none';
        warnIcon.style.display = 'block';
      } else {
        eyeIcon.style.display = 'block';
        warnIcon.style.display = 'none';
      }
    });
  },
};
export { termsOfUse, policy, validateRequired, reLocateDashHeader, listenerEvent };
