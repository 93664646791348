export const state = () => ({
  listUser: [],
});

export const mutations = {
  getUserManage(state, list) {
    state.listUser = list;
  },
};

export const actions = {
  actionGetUserManage({ commit }, list) {
    commit('getUserManage', list);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
