import api from '../api/workflowData';

const state = {
    dataNotification: {},
};

const actions = {
    async getNotification({ commit }) {
        commit("getNotification", await api.getNotificationApi());
    },
    async setNotification({ commit }, data) {
        await api.setNotificationApi(data);
    },
};
const mutations = {
    getNotification: (state, data) => {
        state.dataNotification = data.data
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
