import request from './base/request';
const getExportReport = (data, responseType = false) => {
  if (responseType) {
    return request
      .get(`/export-report/standard?contractor_id=${data}`, { responseType: 'blob' })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw error;
      });
  } else {
    return request
      .get(`/export-report/standard?contractor_id=${data}`)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw error;
      });
  }
};
const getExportReportLite = (data, responseType = false) => {
  if (responseType) {
    return request
      .get(`/export-report/standard?contractor_id=${data.contractor_id}&start_at=${data.start_at}&end_at=${data.end_at}` , { responseType: 'blob' })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw error;
      });
  } else {
    return request
      .get(`/export-report/standard?contractor_id=${data.contractor_id}&start_at=${data.start_at}&end_at=${data.end_at}`)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw error;
      });
  }
};
export { getExportReport, getExportReportLite };
