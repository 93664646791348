export const state = {
  durationId: null,
  scopes: null,
  query: null,
  path: null,
};

export const getters = {
  getDurationId: (state) => state.durationId,
  getScopes: (state) => state.scopes,
  getQuery: (state) => state.query,
  getPath: (state) => state.path,
};

export const mutations = {
  setDurationId(state, data) {
    state.durationId = data;
  },
  updateScopes(state, data) {
    state.scopes = data;
  },
  updateQuery(state, data) {
    state.query = data;
  },
  updatePath(state, data) {
    state.path = data;
  },
};

export const actions = {
  selectDuration({ commit }, data) {
    commit('setDurationId', data);
  },
  setScopes({ commit }, data) {
    commit('updateScopes', data);
  },
  setQuery({ commit }, data) {
    commit('updateQuery', data);
  },
  setPath({ commit }, data) {
    commit('updatePath', data);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
