import request from "./base/request";

const DURATION_SERVICE_URL = "/duration-setting";

const durationSettingApi = (data, options = {}) => {
  return request
    .post(DURATION_SERVICE_URL, data, { headers: options })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

const updateDurationApi = (data) => {
  return request.put(DURATION_SERVICE_URL, data);
};

const getDurations = (params) => {
  return request.get(DURATION_SERVICE_URL, { params });
};

const getSetDuration = (contractorId) => {
  return request.get(DURATION_SERVICE_URL + `/${contractorId}`);
};

const getStartMonth = (contractorId) => {
  return request
  .get(`/get-start-month?contractor_id=${contractorId}`)
  .catch((error) => {
    throw error;
  });
}

const updateStartMonth = (payload) => {
  return request
  .put(`/update-start-month`, payload)
  .catch((error) => {
    throw error;
  });
}

export { durationSettingApi, getDurations, getSetDuration, updateDurationApi, getStartMonth, updateStartMonth };
