const defaultState = () => ({
  dashboard: {
    columnChart: {
      selectedType: 2,
      selectedTab: 0,
      selectedYear: null,
      selectedHalfYear: null, // [1/2017, 2/2017]
      selectedQuarterYear: null, // [1/2017, 2/2017, 3/2017, 4/2017]
      selectedMonthYear: null, // 1/2017,
      selectedCurrency: 1,
    },
    pieChart: {
      selectedType: 2,
      selectedTab: 0,
      selectedYear: null,
      selectedHalfYear: null, // [1/2017, 2/2017]
      selectedQuarterYear: null, // [1/2017, 2/2017, 3/2017, 4/2017]
      selectedMonthYear: null, // 1/2017,
      selectedRange: null,
      selectedCurrency: 1,
    },
    pieIndustryChart: {
      selectedType: 2,
      selectedTab: 0,
      selectedYear: null,
      selectedHalfYear: null, // [1/2017, 2/2017]
      selectedQuarterYear: null, // [1/2017, 2/2017, 3/2017, 4/2017]
      selectedMonthYear: null, // 1/2017,
      selectedRange: null,
      selectedCurrency: 1,
    },
    trendLineChart: {
      selectedType: 2,
      selectedTab: 0,
      selectedYear: null,
      selectedHalfYear: null, // [1, 2]
      selectedQuarterYear: null, // [1, 2, 3, 4]
      selectedMonthYear: null,
      selectedCurrency: 1,
    },
    trendIndustryChart: {
      selectedType: 2,
      selectedTab: 0,
      selectedYear: null,
      selectedHalfYear: null, // [1, 2]
      selectedQuarterYear: null, // [1, 2, 3, 4]
      selectedMonthYear: null,
      selectedCurrency: 1,
    },
    qualityScoreLineChart: {
      selectedType: 2,
      selectedTab: 0,
      selectedYear: null,
      selectedHalfYear: null, // [1, 2]
      selectedQuarterYear: null, // [1, 2, 3, 4]
      selectedMonthYear: null,
      selectedCurrency: 1,
    },
  },
});

export const state = defaultState();
export const mutations = {
  resetState(state) {
    Object.assign({}, defaultState())
    setStateToNull(state)
  },
  updateColumnChart(state, newData) {
    state.dashboard.columnChart = newData;
  },
  updatePieChart(state, newData) {
    state.dashboard.pieChart = newData;
  },
  updatePieIndustryChart(state, newData) {
    state.dashboard.pieIndustryChart = newData;
  },
  updateTrendLineChart(state, newData) {
    state.dashboard.trendLineChart = newData;
  },
  updateTrendIndustryChart(state, newData) {
    state.dashboard.trendIndustryChart = newData;
  },
  updateQualityScoreLineChart(state, newData) {
    state.dashboard.qualityScoreLineChart = newData;
  },
};
export const actions = {
  resetState({ commit }) {
    commit('resetState');
  },
  updateColumnChart({ commit }, data) {
    commit('updateColumnChart', data);
  },
  updateTrendLineChart({ commit }, data) {
    commit('updateTrendLineChart', data);
  },
  updateTrendIndustryChart({ commit }, data) {
    commit('updateTrendIndustryChart', data);
  },
  updateQualityScoreLineChart({ commit }, data) {
    commit('updateQualityScoreLineChart', data);
  },
  updatePieChart({ commit }, data) {
    commit('updatePieChart', data);
  },
  updatePieIndustryChart({ commit }, data) {
    commit('updatePieIndustryChart', data);
  }
}

const setStateToNull = (state) => {
  state.dashboard = {
    columnChart: {
      selectedType: 2,
      selectedTab: 0,
      selectedYear: null,
      selectedHalfYear: null, // [1/2017, 2/2017]
      selectedQuarterYear: null, // [1/2017, 2/2017, 3/2017, 4/2017]
      selectedMonthYear: null, // 1/2017
      selectedCurrency: 1,
    },
    pieChart: {
      selectedType: 2,
      selectedTab: 0,
      selectedYear: null,
      selectedHalfYear: null, // [1/2017, 2/2017]
      selectedQuarterYear: null, // [1/2017, 2/2017, 3/2017, 4/2017]
      selectedMonthYear: null, // 1/2017,
      selectedRange: null,
      selectedCurrency: 1,
    },
    pieIndustryChart: {
      selectedType: 2,
      selectedTab: 0,
      selectedYear: null,
      selectedHalfYear: null, // [1/2017, 2/2017]
      selectedQuarterYear: null, // [1/2017, 2/2017, 3/2017, 4/2017]
      selectedMonthYear: null, // 1/2017,
      selectedRange: null,
      selectedCurrency: 1,
    },
    trendLineChart: {
      selectedType: 2,
      selectedTab: 0,
      selectedYear: null,
      selectedHalfYear: null, // [1, 2]
      selectedQuarterYear: null, // [1, 2, 3, 4]
      selectedMonthYear: null,
      selectedCurrency: 1,
    },
    trendIndustryChart: {
      selectedType: 2,
      selectedTab: 0,
      selectedYear: null,
      selectedHalfYear: null, // [1, 2]
      selectedQuarterYear: null, // [1, 2, 3, 4]
      selectedMonthYear: null,
      selectedCurrency: 1,
    },
    qualityScoreLineChart: {
      selectedType: 2,
      selectedTab: 0,
      selectedYear: null,
      selectedHalfYear: null, // [1, 2]
      selectedQuarterYear: null, // [1, 2, 3, 4]
      selectedMonthYear: null,
      selectedCurrency: 1,
    },
  
  };
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
