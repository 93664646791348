export const state = () => ({
  data: {},
  startAction: {},
  dataAction: {},
  statusOnlyUpdate: false,
  statusBtn: { undo: false, redo: false },
  actionDelete: null,
  actionName: '',
});

export const mutations = {
  updateData(state, dataUpdate) {
    state.data = dataUpdate;
  },

  updateStatus(state, status) {
    state.startAction = status;
  },

  updateDataAction(state, data) {
    state.dataAction = data;
  },

  onlyUpdateData(state) {
    state.statusOnlyUpdate = !state.statusOnlyUpdate;
  },

  updateStatusBtn(state, status) {
    state.statusBtn = status;
  },

  updateStatusDelete(state, status) {
    state.actionDelete = status;
  },

  updateActionName(state, name) {
    state.actionName = name;
  },
};

export const actions = {
  actionUpdate({ commit }, data) {
    commit('updateData', data);
  },

  actionStartUpdate({ commit }, status) {
    commit('updateStatus', status);
  },

  actionUpdateDataAction({ commit }, data) {
    commit('updateDataAction', data);
  },

  statusOnlyUpdateData({ commit }) {
    commit('onlyUpdateData');
  },

  actionUpdateStatusBtn({ commit }, status) {
    commit('updateStatusBtn', status);
  },

  updateActionDelete({ commit }, status) {
    commit('updateStatusDelete', status);
  },

  updateActionName({ commit }, name) {
    commit('updateActionName', name);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
