export function getWidthByTextContent(string, tooltip) {
    const paddingInline = 32;
    let text = document.createElement("span");
    document.body.appendChild(text);
    text.style.width = 'auto';
    text.style.position = 'absolute';
    text.style.whiteSpace = 'no-wrap';
    text.style.font = "Source Han Sans";
    text.style.fontSize = 16 + "px";
    text.innerHTML = string;
    const width = text.clientWidth;
    document.body.removeChild(text);
    let widthTooltip = tooltip || 0
    return width + paddingInline + widthTooltip ;
}
export const setMinMaxSizeColumns = (grid, dataList) => {
    grid.topLeftCells.columns.minSize = 40;
    grid.topLeftCells.columns.maxSize = 100;
    let rowHeadersMin = 40;
    let rowHeadersMax = 100;
    if (dataList.length >= 10000) {
        rowHeadersMin = 50;
    } else if (dataList.length >= 100000) {
        rowHeadersMin = 60;
    }
    grid.rowHeaders.columns.minSize = rowHeadersMin;
    grid.rowHeaders.columns.maxSize = rowHeadersMax;
}

export const getWidthText = (content, styles = {}) => {
    const { fontSize, fontWeight, letterSpacing } = styles;

    let text = document.createElement("span");
    document.body.appendChild(text);
    text.style.width = 'auto';
    text.style.position = 'absolute';
    text.style.whiteSpace = 'no-wrap';
    text.style.font = "Source Han Sans";
    text.style.fontWeight = fontWeight;
    text.style.letterSpacing = letterSpacing
    text.style.fontSize = fontSize;
    text.innerHTML = content;
    const width = text.clientWidth;
    document.body.removeChild(text);
  
    return width
  }

  export function getWidthYAxisByTextContent(content) {
    let text = document.createElement('span');
    document.body.appendChild(text);
    text.style.width = 'auto';
    text.style.position = 'absolute';
    text.style.whiteSpace = 'no-wrap';
    text.style.font = 'Source Han Sans';
    text.style.fontSize = '11px';
    text.style.fontWeight = '500';
    text.style.letterSpacing = '0.03em';
    text.style.lineHeight = '18px';
    text.innerHTML = content;
    const width = text.clientWidth;
    document.body.removeChild(text);
    return width + 20; // 20 is padding right of the label
}
