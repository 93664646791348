<template>
  <v-select
    :items="items"
    v-model="selectVal"
    :placeholder="isHidePlaceholder ? '' : getSelectLabel"
    solo
    dense
    item-text="text"
    item-value="value"
    class="select-item"
    :menu-props="{ contentClass: isFullScreens && selectEport ? 'selecting fullmode select-durations' : 'selecting select-durations item-select-date' }"
    no-data-text="''"
  >
    <template slot="item" slot-scope="{ item }">
      <v-list-item-content>
        <v-list-item-title :class="item.is_selected && 'selected-item'">
          {{ item.text }} <span v-if="item.isShowIcon" class="data-is-registered"></span>
        </v-list-item-title>
      </v-list-item-content>
    </template>
    <template v-slot:selection="{ item }">
      <div class="duration-text">{{ item.text }}<span class="item-selected" v-if="item.is_selected && isSelected">※登録期間中</span></div>
    </template>
  </v-select>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Number,
      default: null,
    },
    isSelected: {
      type: Boolean,
      default: true,
    },
    selectEport : {
      type: Boolean,
      default: true,
    },
    isFullScreens : {
      type: Boolean,
      default: false,
    },
    isHidePlaceholder: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    selectVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', parseInt(val));
      },
    },
    getSelectLabel() {
      return this.$t("emissions_detail.placeholder_please_select")
    }
  },
};
</script>

<style scoped lang="scss">
.duration-text {
  color: $monoBlack;
}
.item-selected {
  margin-left: 5px;
  color: $red;
}
</style>
