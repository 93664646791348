export const handleEncodeToken = (token) => {
  const btoaToken = btoa(token)
  let obj = {}
  splitString(btoaToken, 15).forEach((item, i) => {
    obj[i] = item
  })
  return JSON.stringify(obj) // chunk btoa token to obj 
}

export const handleDecodeToken = (tokenEncodeObj) => { // decode token from chunk object
  let btoaToken = ''
  for (let key in tokenEncodeObj) {
    if (tokenEncodeObj.hasOwnProperty(key)) {
      const value = tokenEncodeObj[key];
      btoaToken = btoaToken + value
    }
  }
  return atob(btoaToken)
}

function splitString(str, chunkSize) { // chunk tring into array
  const result = [];
  for (let i = 0; i < str.length; i += chunkSize) {
    result.push(str.substring(i, i + chunkSize));
  }
  return result;
}