export const state = () => ({
    isDisableMenu: false,
  });
  
  export const mutations = {
    updateDisableMenu(state, newVal) {
      state.isDisableMenu = newVal
    }
  };
  
  export const actions = {
    actionDisableMenu({ commit }, newVal) {
      commit('updateDisableMenu', newVal)
    }
  };
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
  };
  