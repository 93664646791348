import request from './base/request';

const DB_CUSTOM_SERVICE_URL = '/db-customize';

const createDbCustom = (data) => {
  //convert to form
  const body = new FormData();
  const payloadBlob = new Blob([JSON.stringify(data)], { type: 'application/json' });
  body.append('data', payloadBlob);
  return request
    .post(DB_CUSTOM_SERVICE_URL, body)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

const getDbCustom = (params) => {
  return request.get(DB_CUSTOM_SERVICE_URL, { params });
};

const updateDbCustom = (data) => {
  //convert to form
  const body = new FormData();
  const payloadBlob = new Blob([JSON.stringify(data)], { type: 'application/json' });
  body.append('data', payloadBlob);
  body.append('_method', 'put');
  return request
    .post(DB_CUSTOM_SERVICE_URL, body)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

const getCasbeeDetail = (id) => {
  return request.get(`${DB_CUSTOM_SERVICE_URL}/${id}`);
}

export { createDbCustom, getDbCustom, updateDbCustom, getCasbeeDetail };
