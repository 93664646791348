<template>
  <div v-if="$vuetify.breakpoint.width < 1024" class="footer footer-sp" :class="isFullScreen && 'd-none'">
    <v-row class="logo-wrapper">
      <div class="logo logo-app-sp" @click="$router.push({ path: '/' })">
        <img class="logo-img" src="@/assets/icons/footer/logo.svg" alt="" />
        <div class="version">ver. 1.260</div>
      </div>
      <div class="to-top-sp">
        <img @click="handleToTop" class="logo-img back-to-top" src="@/assets/icons/to-top.svg" alt="" />
      </div>
    </v-row>
    <v-row class="footer-content-sp">
      <v-col>
        <p>
          <a href="https://sustech-inc.co.jp/carbonix/" target="_blank" class="router-footer">{{$t("footer.hyperlink_carbonix_official_site")}}</a>
        </p>
        <p>
          <a href="https://faqs.carbonix.ai/" target="_blank" class="router-footer">{{$t("footer.hyperlink_faqs")}}</a>
        </p>
        <p>
          <a href="https://sustech-inc.co.jp/" target="_blank" class="router-footer">{{$t("footer.hyperlink_operating_company")}}</a>
        </p>
        <p>
          <a :href="routers.OTHERS + '/' + routers.TERM_OF_USER" target="_blank" class="router-footer">{{$t("footer.hyperlink_term_of_use")}} </a>
        </p>
        <p>
          <a :href="routers.OTHERS + '/' + routers.PRIVACY" target="_blank" class="router-footer">{{$t("footer.hyperlink_privacy_policy")}}</a>
        </p>
        <p>
          <a href="https://www.truste.or.jp/hssl/validate/01632.php" target="_blank" class="router-footer">{{$t("footer.hyperlink_truste_certified_page")}}</a>
        </p>
      </v-col>
    </v-row>
    <div class="hr" />
    <v-row class="footer-end-sp"> ©︎2022 Sustech Inc. </v-row>
  </div>
  <div v-else class="footer footer-pc" :class="isFullScreen && 'd-none'">
    <v-row class="logo-wrapper">
      <div class="logo logo-app-pc" @click="$router.push({ path: '/' })">
        <img class="logo-img" src="@/assets/icons/footer/logo.svg" alt="" />
        <div class="version">ver. 1.260</div>
      </div>
      <div class="to-top-pc">
        <span class="text-to-top-pc"> PAGE TOP </span>
        <img @click="handleToTop" class="toTop-leave logo-img back-to-top" src="@/assets/icons/to-top.svg" alt="" />
        <img
          @click="handleToTop"
          class="toTop-hover logo-img back-to-top"
          src="@/assets/icons/to-top-leave.svg"
          alt=""
        />
      </div>
    </v-row>
    <v-row class="footer-title-pc">
      <v-col>
        <p>Tools</p>
      </v-col>
      <v-col>
        <p>Settings</p>
      </v-col>
      <!-- <v-col></v-col> -->
      <v-col>
        <p>Others</p>
      </v-col>
    </v-row>
    <div class="hr-short" />
    <v-row class="footer-content-pc">
      <v-col>
        <p><a href="/" @click.prevent="negative('/')" class="router-footer">{{$t("footer.hyperlink_dashboard")}}</a></p>
        <p v-if="!isApprovalRole && !isUserRole && planType?.is_reduction_target"><a href="/reduction" @click.prevent="negative('/reduction')" class="router-footer">{{$t("footer.hyperlink_reduction_target")}}</a></p>
        <p>
          <a href="/emissions/list" @click.prevent="negative('/emissions/list')" class="router-footer">{{$t("footer.hyperlink_emissions_list")}}</a>
        </p>
        <p><a href="/emissions" @click.prevent="negative('/emissions')" class="router-footer">{{$t("footer.hyperlink_registration")}}</a></p>
        <p>
          <a
            href="/emissions/list-templates"
            @click.prevent="negative('/emissions/list-templates')"
            class="router-footer"
            >{{$t("footer.hyperlink_template")}}</a
          >
        </p>
        <p>
          <a
            href="/emissions/list-submitted"
            @click.prevent="negative('/emissions/list-submitted')"
            class="router-footer"
            >{{$t("footer.hyperlink_submit_status")}}</a
          >
        </p>
        <p v-if="!isUserRole">
          <a href="/approval" @click.prevent="negative('/approval')" class="router-footer">{{$t("footer.hyperlink_approve")}}</a>
        </p>
        <p v-if="!isUserRole && planType?.is_primary_data">
          <a href="/primary-data" @click.prevent="negative('/primary-data')" class="router-footer">{{$t("footer.hyperlink_primary_data_merge")}}</a>
        </p>
        <p>
          <a
            href="/others/offset-transaction"
            @click.prevent="negative('/others/offset-transaction')"
            class="router-footer"
            >{{$t("footer.hyperlink_offset_transaction")}}</a
          >
        </p>
        <div @click.prevent="exportReport()"><p class="router-footer-end" style="margin-bottom: 10px">{{$t("footer.hyperlink_report_export")}}</p></div>
      </v-col>
      <v-col>
        <p>
          <a :href="routers.SETTING" @click.prevent="negative(routers.SETTING)" class="router-footer"
            >{{$t("footer.hyperlink_setting_top")}}
          </a>
        </p>
        <p v-if="!isApprovalRole && !isUserRole">
          <a :href="routers.USER_MANAGEMENT" @click.prevent="negative(routers.USER_MANAGEMENT)" class="router-footer"
            >{{$t("footer.hyperlink_user_management")}}
          </a>
        </p>
        <p v-if="!isApprovalRole && !isUserRole">
          <a
            :href="routers.FACILITY_MANAGEMENT"
            @click.prevent="negative(routers.FACILITY_MANAGEMENT)"
            class="router-footer"
            >{{$t("footer.hyperlink_facility_management")}}
          </a>
        </p>
        <p v-if="!isApprovalRole && !isUserRole">
          <a
            :href="routers.VIEWER_MANAGEMENT"
            @click.prevent="negative(routers.VIEWER_MANAGEMENT)"
            class="router-footer"
            >{{$t("footer.hyperlink_view_authority_management")}}
          </a>
        </p>
        <p v-if="!isApprovalRole && !isUserRole">
          <a :href="routers.CALCULATION_TERM" @click.prevent="negative(routers.CALCULATION_TERM)" class="router-footer"
            >{{$t("footer.hyperlink_year_setting")}}
          </a>
        </p>
        <p v-if="!isApprovalRole && !isUserRole && planType?.is_reduction_target">
          <a
            :href="routers.REDUCTION_TARGET_LIST_MANAGEMENT"
            @click.prevent="negative(routers.REDUCTION_TARGET_LIST_MANAGEMENT)"
            class="router-footer"
            >{{$t("footer.hyperlink_reduction_list")}}</a
          >
        </p>
        <p>
          <a
            :href="routers.CREATE_DATA_CUSTOMIZE"
            @click.prevent="negative(routers.CREATE_DATA_CUSTOMIZE)"
            class="router-footer"
            >{{$t("footer.hyperlink_customized_db_management")}}</a
          >
        </p>
        <p v-if="!isApprovalRole && !isUserRole">
          <a
            :href="handleNavigateTo"
            @click.prevent="negative(handleNavigateTo)"
            class="router-footer"
            >{{$t("idea_db_source.hyperlink_db_source")}}</a
          >
        </p>
        <p v-if="!isUserRole">
          <a
            :href="routers.SETTING_AUTO_APPROVAL"
            @click.prevent="negative(routers.SETTING_AUTO_APPROVAL)"
            class="router-footer"
            >{{$t('setting.button_setting_auto_approval')}}</a
          >
        </p>
        <p>
          <a
            :href="routers.SETTING_LANGUAGE"
            @click.prevent="negative(routers.SETTING_LANGUAGE)"
            class="router-footer"
            >{{$t('setting.button_setting_language')}}</a
          >
        </p>
        <p>
          <a
            :href="routers.CHANGE_PASSWORD_USER"
            @click.prevent="negative(routers.CHANGE_PASSWORD_USER)"
            class="router-footer"
            >{{$t("footer.hyperlink_change_password")}}</a
          >
        </p>
       <p v-if="isAdminRole && planType?.is_pcaf">
            <a
              :href="routers.GROUP_ENTERPRISE_MANAGEMENT"
              @click.prevent="negative(routers.GROUP_ENTERPRISE_MANAGEMENT)"
              class="router-footer"
              >{{ $t("group_enterprise_management.button_enterprise_management_setting") }}</a
            >
        </p>
          <p v-if="isAdminRole">
            <a
              :href="routers.LINKED_SERVICE_MANAGEMENT"
              @click.prevent="negative(routers.LINKED_SERVICE_MANAGEMENT)"
              class="router-footer"
              >{{ $t("linked_service_management.button_linked_service_management_setting") }}</a
            >
        </p>
        <p>
          <a
            :href="routers.CSV_FORMAT"
            @click.prevent="negative(routers.CSV_FORMAT)"
            class="router-footer"
            >{{$t("footer.hyperlink_csv_format")}}
          </a>
        </p>
        <div @click="logout()"><p class="router-footer-end">{{$t("footer.hyperlink_logout")}}</p></div>
        <p></p>
      </v-col>
      <v-col>
        <p>
          <a href="https://sustech-inc.co.jp/carbonix/" target="_blank" class="router-footer">{{$t("footer.hyperlink_carbonix_official_site")}}</a>
        </p>
        <p>
          <a href="https://faqs.carbonix.ai/" target="_blank" class="router-footer">{{$t("footer.hyperlink_faqs")}}</a>
        </p>
        <p>
          <a href="https://sustech-inc.co.jp/" target="_blank" class="router-footer">{{$t("footer.hyperlink_operating_company")}}</a>
        </p>
        <p>
          <a :href="routers.OTHERS + '/' + routers.TERM_OF_USER" target="_blank" class="router-footer">{{$t("footer.hyperlink_term_of_use")}}</a>
        </p>
        <p>
          <a :href="routers.OTHERS + '/' + routers.PRIVACY" target="_blank" class="router-footer">{{$t("footer.hyperlink_privacy_policy")}}</a>
        </p>
        <p>
          <a href="https://www.truste.or.jp/hssl/validate/01632.php" target="_blank" class="router-footer">{{$t("footer.hyperlink_truste_certified_page")}}</a>
        </p>
        <p>
          <a
            href="/others/versions"
            target="_blank"
            class="router-footer"
            >{{$t("footer.hyperlink_versions")}}</a
          >
        </p>
      </v-col>
    </v-row>
    <div class="hr last-child" />
    <v-footer class="footer-section">
      <v-col cols="4"></v-col>
      <v-col cols="4" class="text-center">© 2022 Sustech Inc.</v-col>
    </v-footer>
    <error-popup :dialog="errorPopup" :message="errorMessage" @submit="errorPopup = false" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { logoutApi } from '@/api/auth';
import { getExportReport } from '@/api/exportLog';
import { ROUTES } from '@/router/constants';
import ErrorPopup from '@/components/dialogs/error-popup.vue';
import CommonButton from '@/components/utils/button.vue';
import moment from 'moment';
import { getDurations } from '@/api/duration';
import { ROLE } from '@/constants/role';
import { getStartMonth } from "@/api/duration";
export default {
  name: 'Footer',
  components: { ErrorPopup, CommonButton },
  data() {
    return {
      showLogo: ['/home', '/managements/company', '/managements/contractors'],
      dialog: false,
      linkTexts: ['運営会社', '利用規約', 'プライバシーポリシー'],
      routers: ROUTES,
      errorPopup: false,
      errorMessage: '',
      startMonth: null
    };
  },

  props: {
    drawer: {
      type: Boolean,
    },
  },
  computed: {
    ...mapState('userData', ['contractor', 'currentUser', 'isInProductPage', 'planType', 'existDbSource']),
    ...mapState('registerData', ['isFullScreen']),
    isAdminRole() {
      return ROLE.ADMIN === this.currentUser?.user?.role_id;
    },
    isApprovalRole() {
      return ROLE.APPROVAL === this.currentUser?.user?.role_id;
    },
    isUserRole() {
      return ROLE.USER === this.currentUser?.user?.role_id;
    },
    handleNavigateTo() {
      //TODO: Get data API to check user has idea db or not
      if(this.existDbSource) {
        return this.routers.LIST_IDEA_DB_SOURCE;
      } else {
        return this.routers.REGISTER_DB_SOURCE
      }
    },
  },

  methods: {
    handleToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    handleLogout() {
      logoutApi().then(() => this.$router.push({ path: '/auth/login' }).catch(() => {}));
    },

    isShowLogo() {
      return this.showLogo.includes(this.$route.path) ? true : false;
    },
    redirectToEmissionMenu() {
      this.$router.push({ path: ROUTES.LIST_MENU_EMISSION }).catch((err) => {});
    },

    redirectToTemplate() {
      this.$router.push({ path: ROUTES.LIST_EMISSION_TEMPLATES }).catch((err) => {});
    },
    logout() {
      this.$emit('logout');
    },
    negative(url) {
      this.handleToTop();
      if (this.$route.path === url) {
        this.$router.go();
      } else {
        if (this.isInProductPage) {
          this.$router.push({ path: '/products' + url });
        } else {
          this.$router.push({ path: url });
        }
      }
    },
    exportReport() {
      this.$router.push({path: ROUTES.EXPORT_REPORT})
      return
    },
    async getCsvExport(content) {
      await this.getDataStartMonth()
      const fullDate  = moment().format("YYYYMMDDHHmmss");
      const excelName = `CARBONIX_GHG Report-${this.getDurationName()}_${fullDate}.xlsx`;
      const url  = window.URL.createObjectURL(
        new Blob([content], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
      );
      const link = document.createElement("a");
      link.href  = url;
      link.setAttribute("download", excelName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async getDataStartMonth() {
      try {
        const res = await getStartMonth(this.$store.state.userData.contractor)
        this.startMonth = res.data?.start_month
      } catch (error) {
        console.warn(error);
      }
    },
    getDurationName() {
      const currentYear = new Date().getFullYear()
      const dateStart = {
        year: 2017,
        month: this.startMonth - 1,
      }
      const dateEnd = {
        year: this.startMonth === 1 ? currentYear : currentYear + 1,
        month: this.startMonth === 1 ? 11 : this.startMonth - 2,
      }
      const start = moment(dateStart).format("yyyyMM");
      const end = moment(dateEnd).format("yyyyMM");
      return `${start}-${end}`
    },
  },
};
</script>

<style scoped lang="scss">
@import 'footerStyles/index';

.back-to-top {
  cursor: pointer;
}

.footer-sp {
  height: max-content;
  display: block;
  margin-top: 80px;
  .logo-wrapper {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin: unset;
    padding: 40px 35px 0 20px;
    .version {
      color: $monoDark;
      font-size: 12px;
      margin-top: 4px;
      cursor: default;
    }
  }
  .logo-app-sp {
    img {
      width: 140px;
      height: 18px;
    }
    display: flex;
    gap: 10px;
  }
  .logo-app-sp,
  .to-top-sp {
    display: flex;
    align-items: flex-start;
  }

  .footer-content-sp {
    color: $monoDark;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.05em;
    margin-top: 36px;
    margin-bottom: 25px;
    margin-left: 0;
    padding: 0 20px;
    .col {
      padding: unset;
      p {
        margin-bottom: 17px;
      }
    }
  }

  .footer-end-sp {
    justify-content: center;
    padding: 9px 0px 11px;
    margin: 0;
    color: #99a6a9;
    width: 100%;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.05em;
  }
  .hr {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  }
}

.router-footer {
  color: #7c898c;
  text-decoration: none;
}

.router-footer:hover {
  cursor: pointer;
  color: #e4e6e7;
  background: $bgDark;
}

.router-footer-end:hover {
  color: #e4e6e7;
  background: $bgDark;
  padding: 0px 8px 0px;
  margin-left: -8px;
  cursor: pointer;
}

.back-to-top {
  cursor: pointer;
}
.footer-pc {
  height: auto;
  display: block;
  margin-top: 80px;

  .logo-wrapper {
    padding: 43px 40px 0;
    margin: unset;
    display: flex;
    justify-content: space-between;
    margin-bottom: 55px;
    .version {
      color: $monoDark;
      font-size: 12px;
      margin-top: 4px;
      cursor: default;
    }
  }
  .logo-app-pc {
    cursor: pointer;
    img {
      width: 140px;
      height: 18px;
    }
    display: flex;
    gap: 10px;
  }

  .to-top-pc {
    display: flex;
    align-items: center;
    img {
      margin-left: 16px;
    }
    .toTop-hover {
      display: none;
    }
  }
  .to-top-pc:hover {
    .toTop-hover {
      display: block;
    }
    .toTop-leave {
      display: none;
    }
  }

  .text-to-top-pc {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.05em;
    color: $goldMid;
  }

  .footer-title-pc {
    width: 87.17%;
    color: $goldLight;
    font-family: 'Century Gothic Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin: unset;
    padding: 0 0px 12px 40px;
    .col {
      padding: unset;
      P {
        margin-bottom: unset;
        font-family: 'Century Gothic Pro';
      }
    }
  }

  .footer-content-pc {
    color: $monoDark;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.05em;
    width: 87.17%;
    padding: 16px 0px 0 40px;
    &.row {
      margin: unset;
      .col {
        padding: unset;
        p {
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 42px;
          }
        }
      }
    }
  }
  .hr {
    margin: 0 40px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    &.last-child {
      margin: unset;
    }
  }
  .hr-short {
    margin: 0 144px 0 40px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    &.last-child {
      margin: unset;
    }
  }
}
.footer-section {
  background: #132d39;
  color: #99a6a9;
  font-size: 13px;
  letter-spacing: 0.05em;
  padding: 10px;
  height: 44px;
}

#customers th {
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
  color: #a4a184;
}

#customers td {
  color: #7c898c;
}

.footer {
  position: relative !important;
}
.text-right {
  height: 57px;
  text-align: right;
  padding: 0;
}
</style>
