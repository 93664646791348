import request from "./base/request";

export const create = (data) => {
	return request
		.post(`/evidence-storage`, data)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			throw err;
		});
};

export const getDetailFile = (data) => {

	const body = new FormData();
	const payloadBlob = new Blob([JSON.stringify(data)], { type: 'application/json' });
	body.append('data', payloadBlob);
	body.append('_method', 'get');

	return request
		.post(`/evidence-storage`, body, {
			responseType: "blob"
		  })
		.then((res) => {
			return res;		
		})
		.catch((err) => {
			throw err;
		});
};
