import axios from 'axios';

export const prepareCancelRequest = (requests, key) => {
  const cancelTokenSource = axios.CancelToken.source();
  
  return {
    cancelTokenSource: cancelTokenSource,
    config: {
      cancelToken: cancelTokenSource.token,
    },
    requests: {
      ...requests,
      [`${key}`]: cancelTokenSource
    }
  }
}

export const prepareFormData = (params, requestType = 'get') => {
  const body = new FormData();
  const payloadBlob = new Blob([JSON.stringify(params)], { type: 'application/json' });
  body.append('data', payloadBlob);
  body.append('_method', requestType);

  return body;
}

export const handleActionCancelRequest = (requests, key) => {
  if (requests[key]) {
    requests[key].cancel(`Cancel previous request ${key}`);
    requests = {
      ...requests,
      [`${key}`]: null,
    };
  }

  return requests;
}

export const handleFinallyCancelRequest = (requests, key, cancelTokenSource) => {
  if (requests[key] === cancelTokenSource) {
    requests = {
      ...requests,
      [`${key}`]: null,
    };
  };
}
