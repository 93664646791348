import i18n from '@/lang/i18n';

export const GET_ALL = i18n.t('b_export_report.checkbox_select_all');
export const scopeCategoriesData = [
  {
    text: i18n.t('b_export_report.checkbox_select_all'),
    methods: [],
    hasCheckbox: true,
  },
  {
    scope : 1,
    categories : 1,
    text: i18n.t('b_register_product.label_scope_1'),
    text_ik: i18n.t('scope_management_ik.label_scope_1'),
    hasCheckbox: false,
    methods: [
      {
        text: i18n.t('b_register_product.method_calculated_activities_other_than_transportation'),
        text_ik: i18n.t('scope_management_ik.method_calculated_activities_other_than_transportation'),
        hasCheckbox: true,
        row_num_pattern : 54,
        method: 1,
      },
      {
        text: i18n.t('b_register_product.method_calculated_by_fuel_method_scope1'),
        text_ik: i18n.t('scope_management_ik.method_calculated_by_fuel_method'),
        hasCheckbox: true,
        row_num_pattern : 1,
        method: 2,
      },
      {
        text: i18n.t('b_register_product.method_calculated_by_fuel_efficiency_method_scope1'),
        text_ik: i18n.t('scope_management_ik.method_calculated_by_fuel_efficiency_method'),
        hasCheckbox: true,
        row_num_pattern : 3,
        method: 3,
      },
      {
        text: i18n.t('b_register_product.method_calculated_by_the_tkm_method_scope1'),
        text_ik: i18n.t('scope_management_ik.method_calculated_by_the_tkm_method'),
        hasCheckbox: true,
        row_num_pattern : 2,
        method: 4,
      },
    ]
  },
  {
    scope : 2,
    categories : 1,
    text: i18n.t('b_register_product.label_scope_2'),
    text_ik: i18n.t('scope_management_ik.label_scope_2'),
    hasCheckbox: true,
    row_num_pattern : 4,
  },
  {
    scope : 3,
    categories : 1,
    text: i18n.t('b_register_product.label_scope_3_1'),
    text_ik: i18n.t('scope_management_ik.label_scope_3_1'),
    hasCheckbox: true,
    row_num_pattern : 5,
  },
  {
    scope : 3,
    categories : 2,
    text: i18n.t('b_register_product.label_scope_3_2'),
    text_ik: i18n.t('scope_management_ik.label_scope_3_2'),
    hasCheckbox: true,
    row_num_pattern : 6,
  },
  {
    scope : 3,
    categories : 3,
    text: i18n.t('b_register_product.label_scope_3_3'),
    text_ik: i18n.t('scope_management_ik.label_scope_3_3'),
    hasCheckbox: true,
    row_num_pattern : 7,
  },
  {
    scope : 3,
    categories : 4,
    text: i18n.t('b_register_product.label_scope_3_4'),
    text_ik: i18n.t('scope_management_ik.label_scope_3_4'),
    hasCheckbox: false,
    methods: [
      {
        text: i18n.t('b_register_product.method_transportation'),
        text_ik: i18n.t('scope_management_ik.method_transportation'),
        hasCheckbox: false,
        methods: [
          {
            text: i18n.t('b_register_product.method_calculated_by_fuel_method_and_shipping_fee'),
            text_ik: i18n.t('scope_management_ik.method_calculated_by_fuel_method_and_shipping_fee'),
            hasCheckbox: true,
            row_num_pattern : 9,
            method: 1,
            methodLayer2: 4,
          },
          {
            text: i18n.t('b_register_product.method_calculated_by_fuel_efficiency_method'),
            text_ik: i18n.t('scope_management_ik.method_calculated_by_fuel_efficiency_method'),
            hasCheckbox: true,
            row_num_pattern : 11,
            method: 1,
            methodLayer2: 5,
          },
          {
            text: i18n.t('b_register_product.method_calculated_by_the_tkm_method'),
            text_ik: i18n.t('scope_management_ik.method_calculated_by_the_tkm_method'),
            hasCheckbox: true,
            row_num_pattern : 10,
            method: 1,
            methodLayer2: 6,
          },
        ]
      },
      {
        text: i18n.t('b_register_product.method_base'),
        text_ik: i18n.t('scope_management_ik.method_base'),
        hasCheckbox: false,
        methods: [
          {
            text: i18n.t('b_register_product.method_fuel'),
            text_ik: i18n.t('scope_management_ik.method_fuel'),
            hasCheckbox: true,
            row_num_pattern : 12,
            method: 2,
            methodLayer2: 7,
          },
          {
            text: i18n.t('b_register_product.method_electricity'),
            text_ik: i18n.t('scope_management_ik.method_electricity'),
            hasCheckbox: true,
            row_num_pattern : 13,
            method: 2,
            methodLayer2: 8,
          },
        ]
      },
      {
        text:  i18n.t('b_register_product.method_how_to_use_the_shipper_responsibility_report_value'),
        text_ik: i18n.t('scope_management_ik.method_how_to_use_the_shipper_responsibility_report_value'),
        hasCheckbox: true,
        row_num_pattern : 8,
        method: 3,
        methodLayer2: null,
      },
    ]
  }, 
  {
    scope : 3,
    categories : 5,
    text: i18n.t('b_register_product.label_scope_3_5'),
    text_ik: i18n.t('scope_management_ik.label_scope_3_5'),
    hasCheckbox: true,
    row_num_pattern : 14,
  },
  {
    scope : 3,
    categories : 6,
    text: i18n.t('b_register_product.label_scope_3_6'),
    text_ik: i18n.t('scope_management_ik.label_scope_3_6'),
    hasCheckbox: false,
    methods : [
      {
        text: i18n.t('b_register_product.method_calculated_by_fuel_method'),
        text_ik: i18n.t('scope_management_ik.method_calculated_by_fuel_method'),
        hasCheckbox: true,
        row_num_pattern : 16,
        method: 1,
      },
      {
        text: i18n.t('b_register_product.method_calculated_by_fuel_efficiency_method'),
        text_ik: i18n.t('scope_management_ik.method_calculated_by_fuel_efficiency_method'),
        hasCheckbox: true,
        row_num_pattern : 17,
        method: 2,
      },
      {
        text: i18n.t('b_register_product.method_calculated_from_a_passenger_kilometer'),
        text_ik: i18n.t('scope_management_ik.method_calculated_from_a_passenger_kilometer'),
        hasCheckbox: true,
        row_num_pattern : 15,
        method: 3,
      },
      {
        text: i18n.t('b_register_product.method_calculated_from_the_payment_amount'),
        text_ik: i18n.t('scope_management_ik.method_calculated_from_the_payment_amount'),
        hasCheckbox: true,
        row_num_pattern : 18,
        method: 4,
      },
      {
        text: i18n.t('b_register_product.method_calculated_from_the_number_of_accommodation'),
        text_ik: i18n.t('scope_management_ik.method_calculated_from_the_number_of_accommodation'),
        hasCheckbox: true,
        row_num_pattern : 19,
        method: 5,
      },
      {
        text: i18n.t('b_register_product.method_calculated_from_the_number_of_business_trip_days'),
        text_ik: i18n.t('scope_management_ik.method_calculated_from_the_number_of_business_trip_days'),
        hasCheckbox: true,
        row_num_pattern : 20,
        method: 6,
      },
      {
        text: i18n.t('b_register_product.method_calculated_from_the_number_of_employees'),
        text_ik: i18n.t('scope_management_ik.method_calculated_from_the_number_of_employees'),
        hasCheckbox: true,
        row_num_pattern : 21,
        method: 7,
      },
    ]
  },
  {
    scope : 3,
    categories : 7,
    text: i18n.t('b_register_product.label_scope_3_7'),
    text_ik: i18n.t('scope_management_ik.label_scope_3_7'),
    hasCheckbox: false,
    methods: [
      {
        text : i18n.t('b_register_product.method_calculated_by_fuel_method'),
        text_ik: i18n.t('scope_management_ik.method_calculated_by_fuel_method'),
        hasCheckbox: true,
        row_num_pattern : 23,
        method: 1,
      },
      {
        text : i18n.t('b_register_product.method_calculated_by_fuel_efficiency_method'),
        text_ik: i18n.t('scope_management_ik.method_calculated_by_fuel_efficiency_method'),
        hasCheckbox: true,
        row_num_pattern : 24,
        method: 2,
      },
      {
        text : i18n.t('b_register_product.method_calculated_from_a_passenger_kilometer'),
        text_ik: i18n.t('scope_management_ik.method_calculated_from_a_passenger_kilometer'),
        hasCheckbox: true,
        row_num_pattern : 22,
        method: 3,
      },
      {
        text : i18n.t('b_register_product.method_calculated_from_the_payment_amount'),
        text_ik: i18n.t('scope_management_ik.method_calculated_from_the_payment_amount'),
        hasCheckbox: true,
        row_num_pattern : 25,
        method: 4,
      },
      {
        text : i18n.t('b_register_product.method_calculated_from_the_number_of_employees_and_business_days'),
        text_ik: i18n.t('scope_management_ik.method_calculated_from_the_number_of_employees_and_business_days'),
        hasCheckbox: true,
        row_num_pattern : 26,
        method: 5,
      },
    ]
  },
  {
    scope : 3,
    categories : 8,
    text: i18n.t('b_register_product.label_scope_3_8'),
    text_ik: i18n.t('scope_management_ik.label_scope_3_8'),
    hasCheckbox: true,
    row_num_pattern : 27,
  },
  {
    scope : 3,
    categories : 9,
    text: i18n.t('b_register_product.label_scope_3_9'),
    text_ik: i18n.t('scope_management_ik.label_scope_3_9'),
    hasCheckbox: false,
    methods : [
      {
        text : i18n.t('b_register_product.method_transportation'),
        text_ik: i18n.t('scope_management_ik.method_transportation'),
        hasCheckbox : false,
        methods : [
          {
            text :  i18n.t('b_register_product.method_calculated_by_fuel_method_and_shipping_fee'),
            text_ik: i18n.t('scope_management_ik.method_calculated_by_fuel_method_and_shipping_fee'),
            hasCheckbox : true,
            row_num_pattern : 28,
            method: 1,
            methodLayer2: 1,
          },
          {
            text : i18n.t('b_register_product.method_calculated_by_fuel_efficiency_method'),
            text_ik: i18n.t('scope_management_ik.method_calculated_by_fuel_efficiency_method'),
            hasCheckbox : true,
            row_num_pattern : 30,
            method: 1,
            methodLayer2: 2,
          },
          {
            text : i18n.t('b_register_product.method_calculated_by_the_tkm_method'),
            text_ik: i18n.t('scope_management_ik.method_calculated_by_the_tkm_method'),
            hasCheckbox : true,
            row_num_pattern : 29,
            method: 1,
            methodLayer2: 3,
          },
        ]
      },
      {
        text : i18n.t('b_register_product.method_base'),
        text_ik: i18n.t('scope_management_ik.method_base'),
        hasCheckbox : false,
        methods : [
          {
            text :  i18n.t('b_register_product.method_fuel'),
            text_ik: i18n.t('scope_management_ik.method_fuel'),
            hasCheckbox : true,
            row_num_pattern : 31,
            method: 2,
            methodLayer2: 4,
          },
          {
            text : i18n.t('b_register_product.method_electricity'),
            text_ik: i18n.t('scope_management_ik.method_electricity'),
            hasCheckbox : true,
            row_num_pattern : 32,
            method: 2,
            methodLayer2: 5,
          },
        ]
      },    
    ]
  },
  {
    scope : 3,
    categories : 10,
    text: i18n.t('b_register_product.label_scope_3_10'),
    text_ik: i18n.t('scope_management_ik.label_scope_3_10'),
    hasCheckbox: true,
    row_num_pattern : 33,
  },
  {
    scope : 3,
    categories : 11,
    text: i18n.t('b_register_product.label_scope_3_11_export'),
    text_ik: i18n.t('scope_management_ik.label_scope_3_11'),
    hasCheckbox: false,
    methods : [
      {
        text : i18n.t('b_register_product.method_calculated_from_the_direct_use_stage'),
        text_ik: i18n.t('scope_management_ik.method_calculated_from_the_direct_use_stage'),
        hasCheckbox : false,
        methods : [
          {
            text : i18n.t('b_register_product.method_energy_use_products'),
            text_ik: i18n.t('scope_management_ik.method_energy_use_products'),
            hasCheckbox : true,
            row_num_pattern : 34,
            method: 1,
            methodLayer2: 1,
          },
          {
            text : i18n.t('b_register_product.method_fuel_feedstock'),
            text_ik: i18n.t('scope_management_ik.method_fuel_feedstock'),
            hasCheckbox : true,
            row_num_pattern : 35,
            method: 1,
            methodLayer2: 2,
          },
          {
            text : i18n.t('b_register_product.method_GHG_containing_products'),
            text_ik: i18n.t('scope_management_ik.method_GHG_containing_products'),
            hasCheckbox : true,
            row_num_pattern : 36,
            method: 1,
            methodLayer2: 3,
          },
        ]
      },
      {
        text : i18n.t('b_register_product.method_calculated_from_the_emissions_at_the_indirect_use_stage'),
        text_ik: i18n.t('scope_management_ik.method_calculated_from_the_emissions_at_the_indirect_use_stage'),
        hasCheckbox : true,
        row_num_pattern : 37,
        method: 2,
      },  
    ]
  },
  {
    scope : 3,
    categories : 12,
    text: i18n.t('b_register_product.label_scope_3_12_export'),
    text_ik: i18n.t('scope_management_ik.label_scope_3_12'),
    hasCheckbox: true,
    row_num_pattern : 38,
  },
  {
    scope : 3,
    categories : 13,
    text: i18n.t('b_register_product.label_scope_3_13'),
    text_ik: i18n.t('scope_management_ik.label_scope_3_13'),
    hasCheckbox: true,
    row_num_pattern : 39,
  },
  {
    scope : 3,
    categories : 14,
    text:i18n.t('b_register_product.label_scope_3_14'),
    text_ik: i18n.t('scope_management_ik.label_scope_3_14'),
    hasCheckbox: true,
    row_num_pattern : 40,
  },
  {
    scope : 3,
    categories : 15,
    text: i18n.t('b_register_product.label_scope_3_15'),
    text_ik: i18n.t('scope_management_ik.label_scope_3_15'),
    hasCheckbox: false,
    methods: [
      {
        text : i18n.t('b_register_product.method_stock'),
        text_ik: i18n.t('scope_management_ik.method_stock'),
        hasCheckbox: true,
        row_num_pattern : 41,
        method: 1,
      },
      {
        text : i18n.t('b_register_product.method_debt_bond_loan'),
        text_ik: i18n.t('scope_management_ik.method_debt_bond_loan'),
        hasCheckbox: true,
        row_num_pattern : 42,
        method: 2,
      },
      {
        text : i18n.t('b_register_product.method_project_finance'),
        text_ik: i18n.t('scope_management_ik.method_project_finance'),
        hasCheckbox: true,
        row_num_pattern : 43,
        method: 3,
      },
      {
        text : i18n.t('b_register_product.method_asset_management_customer_service'),
        text_ik: i18n.t('scope_management_ik.method_asset_management_customer_service'),
        hasCheckbox: true,
        row_num_pattern : 44,
        method: 4,
      },
    ]
  },
  {
    scope : 3,
    categories : 16,
    text : i18n.t('b_register_product.label_scope_3_16'),
    text_ik: i18n.t('scope_management_ik.label_scope_3_16'),
    hasCheckbox: true,
    row_num_pattern : 45,
  },
  {
    scope : 4,
    categories : 1,
    text : i18n.t('b_register_product.label_scope_4'),
    text_ik: i18n.t('scope_management_ik.label_scope_4'),
    hasCheckbox: true,
    row_num_pattern : 46,
  }
]

export const methodGhg = [
  {
    text : i18n.t('register_data.radio_listed_stock'),
    hasCheckbox: true,
    row_num_pattern : 47,
    method: 1,
  },
  {
    text : i18n.t('register_data.radio_corporate_loan'),
    hasCheckbox: true,
    row_num_pattern : 48,
    method: 2,
  },
  {
    text : i18n.t('b_register_product.method_project_finance'),
    hasCheckbox: true,
    row_num_pattern : 49,
    method: 3,
  },
  {
    text : i18n.t('register_data.radio_commercial_real_estate'),
    hasCheckbox: true,
    row_num_pattern : 50,
    method: 4,
  },
  {
    text : i18n.t('register_data.radio_housing_loan'),
    hasCheckbox: true,
    row_num_pattern : 51,
    method: 5,
  },
  {
    text : i18n.t('register_data.radio_car_loan'),
    hasCheckbox: true,
    row_num_pattern : 52,
    method: 6,
  },
]

export const oldmethod = [
  {
    text : i18n.t('register_data.radio_stock'),
    text_ik: i18n.t('scope_management_ik.method_stock'),
    hasCheckbox: true,
    row_num_pattern : 41,
    method: 1,
  },
  {
    text : i18n.t('register_data.radio_debt_bond_loan'),
    text_ik: i18n.t('scope_management_ik.method_debt_bond_loan'),
    hasCheckbox: true,
    row_num_pattern : 42,
    method: 2,
  },
  {
    text : i18n.t('b_register_product.method_project_finance'),
    text_ik: i18n.t('scope_management_ik.method_project_finance'),
    hasCheckbox: true,
    row_num_pattern : 43,
    method: 3,
  },
  {
    text :i18n.t('register_data.radio_asset_management_customer_service'),
    text_ik : i18n.t('scope_management_ik.method_asset_management_customer_service'),
    hasCheckbox: true,
    row_num_pattern : 44,
    method: 4,
  },
]

export const pcafMethod = [
  {
    text : i18n.t('register_data.radio_bottom_up_method'),
    text_ik : i18n.t('register_data.radio_bottom_up_method'),
    hasCheckbox : false,
    methods : [
      {
        text : i18n.t('register_data.radio_listed_stock'),
        text_ik : i18n.t('register_data.radio_listed_stock'),
        hasCheckbox: true,
        row_num_pattern : 47,
        method: 1,
        methodLayer2: 5,
      },
      {
        text : i18n.t('register_data.radio_corporate_loan'),
        text_ik : i18n.t('register_data.radio_corporate_loan'),
        hasCheckbox: true,
        row_num_pattern : 48,
        method: 1,
        methodLayer2: 6,
      },
      {
        text : i18n.t('b_register_product.method_project_finance'),
        text_ik : i18n.t('b_register_product.method_project_finance'),
        hasCheckbox: true,
        row_num_pattern : 49,
        method: 1,
        methodLayer2: 7,
      },
      {
        text : i18n.t('register_data.radio_commercial_real_estate'),
        text_ik : i18n.t('register_data.radio_commercial_real_estate'),
        hasCheckbox: true,
        row_num_pattern : 50,
        method: 1,
        methodLayer2: 8,
      },
      {
        text : i18n.t('register_data.radio_housing_loan'),
        text_ik : i18n.t('register_data.radio_housing_loan'),
        hasCheckbox: true,
        row_num_pattern : 51,
        method: 1,
        methodLayer2: 9,
      },
      {
        text : i18n.t('register_data.radio_car_loan'),
        text_ik : i18n.t('register_data.radio_car_loan'),
        hasCheckbox: true,
        row_num_pattern : 52,
        method: 1,
        methodLayer2: 10,
      },
    ]
  },
  {
    text : i18n.t('register_data.radio_top_up_method'),
    text_ik : i18n.t('register_data.radio_top_up_method'),
    hasCheckbox : false,
    methods : [
      {
        text : i18n.t('register_data.radio_listed_stock'),
        text_ik : i18n.t('register_data.radio_listed_stock'),
        hasCheckbox: true,
        row_num_pattern : 53,
        method: 2,
        methodLayer2: 11,
      },
      {
        text : i18n.t('register_data.radio_corporate_loan'),
        text_ik : i18n.t('register_data.radio_corporate_loan'),
        hasCheckbox: true,
        row_num_pattern : 54,
        method: 2,
        methodLayer2: 12,
      },
      {
        text : i18n.t('b_register_product.method_project_finance'),
        text_ik : i18n.t('b_register_product.method_project_finance'),
        hasCheckbox: true,
        row_num_pattern : 55,
        method: 2,
        methodLayer2: 13,
      },
      {
        text : i18n.t('register_data.radio_commercial_real_estate'),
        text_ik : i18n.t('register_data.radio_commercial_real_estate'),
        hasCheckbox: true,
        row_num_pattern : 56,
        method: 2,
        methodLayer2: 14,
      },
      {
        text : i18n.t('register_data.radio_housing_loan'),
        text_ik : i18n.t('register_data.radio_housing_loan'),
        hasCheckbox: true,
        row_num_pattern : 57,
        method: 2,
        methodLayer2: 15,
      },
      {
        text : i18n.t('register_data.radio_car_loan'),
        text_ik : i18n.t('register_data.radio_car_loan'),
        hasCheckbox: true,
        row_num_pattern : 58,
        method: 2,
        methodLayer2: 16,
      },
    ]
  },    
]

export const newPcafMethod = [
  {
    text : i18n.t('register_data.popup_method_pcaf1'),
    text_ik : i18n.t('register_data.popup_method_pcaf1'),
    hasCheckbox: true,
    row_num_pattern : 47,
    method: 1,
    // methods: [
    //   {
    //     text: `${i18n.t("pcaf_update.listed_stocks")}_` + '認証済み排出量',
    //     text_ik: `${i18n.t("pcaf_update.listed_stocks")}_` + '認証済み排出量',
    //     text_select: i18n.t('register_data.popup_method_pcaf1') + `_${i18n.t("pcaf_update.listed_stocks")}_` + '認証済み排出量',
    //     hasCheckbox: true,
    //     row_num_pattern : 47,
    //     category_type: 1,
    //     data_type: 1
    //   },
    //   {
    //     text: `${i18n.t("pcaf_update.listed_stocks")}_` + '未認証の排出量',
    //     text_ik: `${i18n.t("pcaf_update.listed_stocks")}_` + '未認証の排出量',
    //     hasCheckbox: true,
    //     row_num_pattern : 47,
    //     text_select: i18n.t('register_data.popup_method_pcaf1') + `_${i18n.t("pcaf_update.listed_stocks")}_` + '未認証の排出量',
    //     category_type: 1,
    //     data_type: 2
    //   },
    //   {
    //     text: `${i18n.t("pcaf_update.listed_stocks")}_` + 'エネルギー消費量',
    //     text_ik: `${i18n.t("pcaf_update.listed_stocks")}_` + 'エネルギー消費量',
    //     hasCheckbox: true,
    //     row_num_pattern : 47,
    //     text_select: i18n.t('register_data.popup_method_pcaf1') + `_${i18n.t("pcaf_update.listed_stocks")}_` + 'エネルギー消費量',
    //     category_type: 1,
    //     data_type: 3
    //   },
    //   {
    //     text: `${i18n.t("pcaf_update.listed_stocks")}_` + '生産量',
    //     text_ik: `${i18n.t("pcaf_update.listed_stocks")}_` + '生産量',
    //     hasCheckbox: true,
    //     row_num_pattern : 47,
    //     text_select: i18n.t('register_data.popup_method_pcaf1') + `_${i18n.t("pcaf_update.listed_stocks")}_` + '生産量',
    //     category_type: 1,
    //     data_type: 4
    //   },
    //   {
    //     text: `${i18n.t("pcaf_update.listed_stocks")}_` + '営業収益',
    //     text_ik: `${i18n.t("pcaf_update.listed_stocks")}_` + '営業収益',
    //     hasCheckbox: true,
    //     row_num_pattern : 47,
    //     text_select: i18n.t('register_data.popup_method_pcaf1') + `_${i18n.t("pcaf_update.listed_stocks")}_` + '営業収益',
    //     category_type: 1,
    //     data_type: 5
    //   },
    //   {
    //     text: `${i18n.t("pcaf_update.listed_stocks")}_` + '総資産',
    //     text_ik: `${i18n.t("pcaf_update.listed_stocks")}_` + '総資産',
    //     hasCheckbox: true,
    //     row_num_pattern : 47,
    //     text_select: i18n.t('register_data.popup_method_pcaf1') + `_${i18n.t("pcaf_update.listed_stocks")}_` + '総資産',
    //     category_type: 1,
    //     data_type: 6
    //   },

    //   {
    //     text: `${i18n.t("pcaf_update.corporate_bond")}_` + '認証済み排出量',
    //     text_ik: `${i18n.t("pcaf_update.corporate_bond")}_` + '認証済み排出量',
    //     hasCheckbox: true,
    //     row_num_pattern : 47,
    //     category_type: 2,
    //     data_type: 1
    //   },
    //   {
    //     text: `${i18n.t("pcaf_update.corporate_bond")}_` + '未認証の排出量',
    //     text_ik: `${i18n.t("pcaf_update.corporate_bond")}_` + '未認証の排出量',
    //     hasCheckbox: true,
    //     row_num_pattern : 47,
    //     category_type: 2,
    //     data_type: 2
    //   },
    //   {
    //     text: `${i18n.t("pcaf_update.corporate_bond")}_` + 'エネルギー消費量',
    //     text_ik: `${i18n.t("pcaf_update.corporate_bond")}_` + 'エネルギー消費量',
    //     hasCheckbox: true,
    //     row_num_pattern : 47,
    //     category_type: 2,
    //     data_type: 3
    //   },
    //   {
    //     text: `${i18n.t("pcaf_update.corporate_bond")}_` + '生産量',
    //     text_ik: `${i18n.t("pcaf_update.corporate_bond")}_` + '生産量',
    //     hasCheckbox: true,
    //     row_num_pattern : 47,
    //     category_type: 2,
    //     data_type: 4
    //   },
    //   {
    //     text: `${i18n.t("pcaf_update.corporate_bond")}_` + '営業収益',
    //     text_ik: `${i18n.t("pcaf_update.corporate_bond")}_` + '営業収益',
    //     hasCheckbox: true,
    //     row_num_pattern : 47,
    //     category_type: 2,
    //     data_type: 5
    //   },
    //   {
    //     text: `${i18n.t("pcaf_update.corporate_bond")}_` + '総資産',
    //     text_ik: `${i18n.t("pcaf_update.corporate_bond")}_` + '総資産',
    //     hasCheckbox: true,
    //     row_num_pattern : 47,
    //     category_type: 2,
    //     data_type: 6
    //   },
    // ]
  },
  {
    text : i18n.t('register_data.popup_method_pcaf2'),
    text_ik : i18n.t('register_data.popup_method_pcaf2'),
    hasCheckbox: true,
    row_num_pattern : 48,
    method: 2,
  },
  {
    text : i18n.t('register_data.popup_method_pcaf3'),
    text_ik : i18n.t('register_data.popup_method_pcaf3'),
    hasCheckbox: true,
    row_num_pattern : 49,
    method: 3,
  },
  {
    text : i18n.t('register_data.popup_method_pcaf4'),
    text_ik : i18n.t('register_data.popup_method_pcaf4'),
    hasCheckbox: true,
    row_num_pattern : 50,
    method: 4,
  },
  {
    text : i18n.t('register_data.popup_method_pcaf5'),
    text_ik : i18n.t('register_data.popup_method_pcaf5'),
    hasCheckbox: true,
    row_num_pattern : 51,
    method: 5,
  },
  {
    text : i18n.t('register_data.popup_method_pcaf6'),
    text_ik : i18n.t('register_data.popup_method_pcaf6'),
    hasCheckbox: true,
    row_num_pattern : 52,
    method: 6,
  },
  {
    text : i18n.t('register_data.popup_method_pcaf7'),
    text_ik : i18n.t('register_data.popup_method_pcaf7'),
    hasCheckbox: true,
    row_num_pattern : 53,
    method: 7,
    // methods: [
    //   {
    //     text: 'ソブリン債からの排出量（認証済）',
    //     text_ik: 'ソブリン債からの排出量（認証済）',
    //     text_select: i18n.t('register_data.popup_method_pcaf7') + '_ソブリン債からの排出量（認証済）',
    //     hasCheckbox: true,
    //     row_num_pattern : 53,
    //     data_type: 1
    //   },
    //   {
    //     text: 'ソブリン債からの排出量（主要な物理的活動データ）',
    //     text_ik: 'ソブリン債からの排出量（主要な物理的活動データ）',
    //     text_select: i18n.t('register_data.popup_method_pcaf7') + '_ソブリン債からの排出量（主要な物理的活動データ）',
    //     hasCheckbox: true,
    //     row_num_pattern : 53,
    //     data_type: 2
    //   },
    // ]
  },
]

export const newPcafMethodOriginal = [
  {
    text : i18n.t('register_data.popup_method_pcaf1'),
    text_ik : i18n.t('register_data.popup_method_pcaf1'),
    hasCheckbox: true,
    row_num_pattern : 47,
    method: 1,
  },
  {
    text : i18n.t('register_data.popup_method_pcaf2'),
    text_ik : i18n.t('register_data.popup_method_pcaf2'),
    hasCheckbox: true,
    row_num_pattern : 48,
    method: 2,
  },
  {
    text : i18n.t('register_data.popup_method_pcaf3'),
    text_ik : i18n.t('register_data.popup_method_pcaf3'),
    hasCheckbox: true,
    row_num_pattern : 49,
    method: 3,
  },
  {
    text : i18n.t('register_data.popup_method_pcaf4'),
    text_ik : i18n.t('register_data.popup_method_pcaf4'),
    hasCheckbox: true,
    row_num_pattern : 50,
    method: 4,
  },
  {
    text : i18n.t('register_data.popup_method_pcaf5'),
    text_ik : i18n.t('register_data.popup_method_pcaf5'),
    hasCheckbox: true,
    row_num_pattern : 51,
    method: 5,
  },
  {
    text : i18n.t('register_data.popup_method_pcaf6'),
    text_ik : i18n.t('register_data.popup_method_pcaf6'),
    hasCheckbox: true,
    row_num_pattern : 52,
    method: 6,
  },
  {
    text : i18n.t('register_data.popup_method_pcaf7'),
    text_ik : i18n.t('register_data.popup_method_pcaf7'),
    hasCheckbox: true,
    row_num_pattern : 53,
    method: 7,
  },
]

export const scopeCategorySourcesData = [
  { scope: 1, method: 2, data: 'energy_type', dbKey: 'item_name' },
  { scope: 1, method: 3, data: 'fuel', dbKey: 'item_name' },
  { scope: 1, method: 4, data: 'wsu_source', dbKey: 'source' },
  { scope: 1, method: 1, data: 'energy_type', dbKey: 'item_name' },
  { scope: 2, method: 1, data: 'energy_type', dbKey: 'item_name' },
  { scope: 3, category: 1, data: 'wsu_source', dbKey: 'source' },
  { scope: 3, category: 2, data: 'wsu_source', dbKey: 'source' },
  { scope: 3, category: 3, data: 'wsu_source', dbKey: 'source' },
  { scope: 3, category: 4, method: 1, methodLayer2: 4, data: 'energy_type', dbKey: 'item_name' },
  { scope: 3, category: 4, method: 1, methodLayer2: 5, data: 'fuel', dbKey: 'item_name' },
  { scope: 3, category: 4, method: 1, methodLayer2: 6, data: 'wsu_source', dbKey: 'source' },
  { scope: 3, category: 4, method: 2, methodLayer2: 7, data: 'energy_type', dbKey: 'item_name' },
  { scope: 3, category: 4, method: 2, methodLayer2: 8, data: 'energy_type', dbKey: 'item_name' },
  { scope: 3, category: 5, data: 'wsu_source', dbKey: 'source' },
  { scope: 3, category: 6, method: 1, data: 'energy_type', dbKey: 'item_name' },
  { scope: 3, category: 6, method: 2, data: 'fuel', dbKey: 'item_name' },
  { scope: 3, category: 6, method: 3, data: 'wsu_source', dbKey: 'source' },
  { scope: 3, category: 6, method: 4, data: 'wsu_source', dbKey: 'source' },
  { scope: 3, category: 6, method: 5, data: 'wsu_source', dbKey: 'source' },
  { scope: 3, category: 6, method: 6, data: 'wsu_source', dbKey: 'source' },
  { scope: 3, category: 6, method: 7, data: 'wsu_source', dbKey: 'source' },
  { scope: 3, category: 7, method: 1, data: 'energy_type', dbKey: 'item_name' },
  { scope: 3, category: 7, method: 2, data: 'fuel', dbKey: 'item_name' },
  { scope: 3, category: 7, method: 3, data: 'wsu_source', dbKey: 'source' },
  { scope: 3, category: 7, method: 4, data: 'wsu_source', dbKey: 'source' },
  { scope: 3, category: 7, method: 5, data: 'wsu_source', dbKey: 'source' },
  { scope: 3, category: 8, data: 'wsu_source', dbKey: 'source' },
  { scope: 3, category: 9, method: 1, methodLayer2: 1, data: 'energy_type', dbKey: 'item_name' },
  { scope: 3, category: 9, method: 1, methodLayer2: 2, data: 'fuel', dbKey: 'item_name' },
  { scope: 3, category: 9, method: 1, methodLayer2: 3, data: 'wsu_source', dbKey: 'source' },
  { scope: 3, category: 9, method: 2, methodLayer2: 4, data: 'energy_type', dbKey: 'item_name' },
  { scope: 3, category: 9, method: 2, methodLayer2: 5, data: 'energy_type', dbKey: 'item_name' },
  { scope: 3, category: 10, data: 'wsu_source', dbKey: 'source' },
  { scope: 3, category: 11, data: 'wsu_source', dbKey: 'source' },
  { scope: 3, category: 12, data: 'wsu_source', dbKey: 'source' },
  { scope: 3, category: 13, data: 'wsu_source', dbKey: 'source' },
  { scope: 3, category: 15,  data: 'ides_industry', dbKey: 'item_name' }
];
