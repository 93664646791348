import axios from 'axios';
import request from './base/request';
import { prepareCancelRequest, prepareFormData, handleActionCancelRequest, handleFinallyCancelRequest } from '@/api/base/prepareRequest';

let requests = {
  getEmissionsByPeriod: null,
};
export const getEmissionsByPeriod = (query = {}) => {
  if (query.hasOwnProperty('branch_id')) {
    const { branch_id } = query
    query.business_name = branch_id
  }
  return prepareApiData({
    payload: query,
    url: `dashboard`,
    key: 'getEmissionsByPeriod',
  })
};

export const getEmissionByPeriodDetail = (query = {}) => {
  const cancelTokenSource = axios.CancelToken.source();

  const config = {
    params: query,
    cancelToken: cancelTokenSource.token,
  };

  if (requests.getEmissionByPeriodDetail) {
    // requests.getEmissionByPeriodDetail.cancel('Cancel getEmissionByPeriodDetail');
    requests.getEmissionByPeriodDetail = null;
  }

  requests = {
    ...requests,
    getEmissionByPeriodDetail: cancelTokenSource,
  };

  return request
    .get('dashboard/detail-period', config)
    .catch((errors) => {
      throw errors;
    })
    .finally(() => {
      requests = {
        ...requests,
        getEmissionByPeriodDetail: null,
      };
    });
};

export const getEmissionsByCategory = (query = {}) => {
  return prepareApiData({
    payload: query,
    url: `dashboard/ghg-emissions-category`,
    key: 'getEmissionsByCategory',
  })
};

export const getEmissionByCategoryDetail = (query = {}) => {
  const { cancelTokenSource, config } = prepareCancelRequest();

  if (requests.getEmissionByCategoryDetail) {
    // requests.getEmissionByCategoryDetail.cancel('Cancel getEmissionByCategoryDetail');
    requests.getEmissionByCategoryDetail = null;
  }

  requests = {
    ...requests,
    getEmissionByCategoryDetail: cancelTokenSource,
  };

  const body = new FormData();
  const payloadBlob = new Blob([JSON.stringify(query)], { type: 'application/json' });
  body.append('data', payloadBlob);
  body.append('_method', 'get');

  return request
    .post(`dashboard/detail-ghg-emissions-category`, body, config)
    .catch((errors) => {
      throw errors;
    })
    .finally(() => {
      requests = {
        ...requests,
        getEmissionByCategoryDetail: null,
      };
    });
};

export const getDetailScopeBranch = (query = {}) => {
  const config = {
    params: query,
  };

  const body = new FormData();
  const payloadBlob = new Blob([JSON.stringify(query)], { type: 'application/json' });
  body.append('data', payloadBlob);
  body.append('_method', 'get');

  return request.post(`dashboard/detail-scope-branch`, body).catch((errors) => {
    console.warn(errors);
    throw errors;
  });
};

export const getEmissionByCategoryDetailTop5 = (query = {}) => {
  return prepareApiData({
    payload: query,
    url: `dashboard/list-item-most-emission`,
    key: 'getEmissionByCategoryDetailTop5',
  })
};


export const getPcafDashboard = (params) => {
  return request
    .get('/pcaf/dashboard', {params})
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};
export const getStartMonth = (params) => {
  return request.get('/get-start-month', { params });
};

const prepareApiData = (params) => {
  const { payload, url, key } = params;

  const data = prepareCancelRequest(requests, key);
  const { cancelTokenSource, config } = data;
  
  handleActionCancelRequest(requests, key);
  requests = data.requests;
  
  const body = prepareFormData(payload);
  return request
    .post(url, body, config)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    })
    .finally(() => {
      handleFinallyCancelRequest(requests, key, cancelTokenSource);
    });
}
