const defaultState = () => ({
  supplierData: null,
  dataInfo: {
    year: null,
    month: null,
    note: null
  },
  isRegisteredSucceeded: false,
  primaryDataForm: {}
});
export const state = defaultState();
export const mutations = {
  updateSupplierData(state, data) {
    state.supplierData = data;
  },
  updateDataInfo(state, data) {
    state.dataInfo = {
      year: data.selectedYear,
      month: data.selectedMonth,
      note: data.note,
    };
  },
  updateStatusDataSupplier(state, data) {
    state.isRegisteredSucceeded = data;
  },
  updatePrimaryDataForm(state, data) {
    state.primaryDataForm = data;
  },
  resetState(state) {
    Object.assign(state, defaultState());
  }
};

export const actions = {
  setUpdateSupplierData({ commit }, data) {
    commit('updateSupplierData', data);
  },
  setDataInfo({ commit }, data) {
    commit('updateDataInfo', data);
  },
  setStatusDataSupplier({ commit }, data) {
    commit('updateStatusDataSupplier', data);
  },
  setPrimaryDataForm({ commit }, data) {
    commit('updatePrimaryDataForm', data);
  },
  resetState({ commit }) {
    commit('resetState');
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
