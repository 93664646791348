<template>
  <div class="breadcrumb-items">
    <router-link
      v-for="(item, i) in items"
      :key="i"
      class="breadcrumb-item"
      :to="{ path: item.href, query: { ...$route.query} }"
      :class="{ 'disabled-click': item.disabled || i === items.length - 1 }"
    >
      <div :class="{ disabled: item.disabled || i === items.length - 1 }">{{ item.text }}</div>
      <span class="arrow_break" v-if="i !== items.length - 1">></span>
      <!-- <img v-if="i !== items.length - 1" src="@/assets/icons/breadcrumb/arrow.svg" alt="" /> -->
    </router-link>
  </div>
</template>

<script>
export default {
  data: () => ({}),
  props: {
    items: {
      type: Array,
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumb-items {
  display: flex;
  flex-flow: row;
  min-height: 24px;
  .breadcrumb-item {
    display: flex;
    align-items: center;
    text-decoration: unset;
    img {
      width: 8px;
      height: 20px;
      margin: 0 8px;
    }
    div {
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.05em;
      color: $goldMid;
      width: max-content;
      &.disabled {
        color: $monoBlack;
      }
    }
    &.disabled-click {
      pointer-events: none;
    }
  }
  a.breadcrumb-item > div:hover {
    text-decoration: underline;
  }
}
.arrow_break {
  font-family: 'Source Han Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: $monoMid;
  padding: 0 8px;
}
</style>
