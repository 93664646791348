import {makeNewId} from '@/concerns/utils/master-or-customize-db';
import {BLANK_ID} from '@/constants/registerData';

/**
 * @param {array} viewSources
 * @param {array} destinations [{id: 1,...},{id: 2,...}]
 * @param {array} indexRows [1, 2, 3]
 * @param {object} replacementFields {viewSourceKey: destinationKey}
 * @param isUpdateEmission
 */
export const batchReplacementViewCollection = (
    viewSources,
    destinations,
    indexRows,
    replacementFields,
    isUpdateEmission = false,
) => {
    const itemHasBlankId = viewSources.filter((item) => item.id && item.id === BLANK_ID);
    if (itemHasBlankId.length > 0) {
        // filter viewSources if it has any item with id blank
        viewSources = itemHasBlankId;
    }
    for (let i = 0; i < indexRows.length; i++) {
        const source      = viewSources[i];
        source.isSyncing  = false;
        const destination = destinations[i];

        for (const key in replacementFields) {
            source[key] = destination[replacementFields[key]];
            if (isUpdateEmission && key === 'emissions') {
                source[key] = destination['emissions'] === null ? 'N/A' : destination['emissions']
            }
            if (key === 'energy_type') {
                const type = destination['type'] ? 1 : 0;
                if (destination['type']) {
                    source[key] = makeNewId(destination['db_master']['id'], type);
                } else {
                    source[key] = makeNewId(destination['db_customize']['id'], type);
                }
            }
        }
    }

    return true;
};

export const batchReplacementItemsAddedFailedViewCollection = (viewSources, indexRows) => {
    for (let i = 0; i < indexRows.length; i++) {
        const indexRow = indexRows[i];
        const source   = viewSources[indexRow];

        source['id'] = BLANK_ID;
    }

    return true;
};

export const batchReplacementItemsDeleteInUpdate = (viewSources, indexRows, status = true) => {
    for (let i = 0; i < indexRows.length; i++) {
        const indexRow = indexRows[i];
        const source   = viewSources[indexRow];

        source['isDeleted'] = status;
    }

    return true;
};

/**
 * @returns Promise
 */
export const emptyPromise = () => {
    return new Promise((resolve) => {
        resolve(true);
    });
};

/**
 * @param {string|null} value
 * @returns number
 */
export const formatValue = (value) => {
    if (!value && value !== 0) {
        return null
    }
    return Number((value || 0).toString().replace(/,/i, ''));
};
