import {
  MESSAGE_NOT_NULL,
  MESSAGE_INCORRECT_DATE,
  MESSAGE_IS_NUMBER,
  MESSAGE_MAX_128_CHARACTOR
} from '@/constants/registerData'
import { formatValue, $_helper_isNumberType } from '@/concerns/newRegisterData/wijmo.helper';
import i18n from '@/lang/i18n';
const regexMail =  /^(([^<>()\[\\,;:\s@"\u0080-\uFFFF]+(\.[^<>()\[\]\\,;:\s@"\u0080-\uFFFF]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const regexPassword =
  /^(((?=.*[A-Za-z])(?=.*\d))|((?=.*[a-z])(?=.*[A-Z]))|((?=.*[A-Za-z])(?=.*[![\]¥"#$%&'()\-^@;:,.\\_/=~|`{+*}<>?]))|((?=.*\d)(?=.*[![\]¥"#$%&'()\-^@;:,.\\_/=~|`{+*}<>?])))[A-Za-z\d[![\]¥"#$%&'()\-^@;:,.\\_/=~|`{+*}<>?]{8,}$/;
import { BLANK_ID } from "@/constants/registerData";

const regexPhoneNumber = /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d*)\)?)[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?)+)(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i

export const validateMaximumCharactor = (item, maximumCharactor) => {
  if (item) {
    if (item.replace(/\s+/g, '') === '') {
      return i18n.t("validation.error_required_field");
    } else if (item.length > maximumCharactor) {
      // return `Maximum number of characters is ${maximumCharactor}!`;
      return maximumCharactor === 21 ? 20 : i18n.t('validation.error_maximum_20_charactors', { charactors: maximumCharactor });
    } else {
      return null;
    }
  } else {
    return i18n.t("validation.error_required_field");
  }
};

export const validateMaximumCharactorOnlyFacility = (item, maximumCharactor, is128Charactor = false) => {
  if (item) {
    if (item.length > maximumCharactor) {
      // return `Maximum number of characters is ${maximumCharactor}!`;
      return is128Charactor ? MESSAGE_MAX_128_CHARACTOR : i18n.t('validation.error_maximum_20_charactors', { charactors: maximumCharactor })
    } else {
      return null;
    }
  }
  return null;
};

export const validateNumberField = (input) => {
  if (input) {
    let numberData = input.replace(/[^\d.]/g, '');
    if (Number(input) < 0) {
      return i18n.t('validation.error_positive_value')
    }
    if (numberData && input.length - numberData.length == 0) {
      return null;
    } else {
      return i18n.t('validation.error_number_field')
    }
  } else {
    return i18n.t("validation.error_required_field");
  }
};

export const getErrorValidate = (item, propName, notNullList, numberField, max25Charactor, max128Charactor) => {

  if (notNullList.includes(propName)) {
    if (item[propName] === null || item[propName] === '' || item[propName] === undefined || !item[propName] && item[propName] !== 0) {
      return MESSAGE_NOT_NULL;
    }
  }

  if (numberField.includes(propName)) {
    if(!item[propName] && item[propName] !== 0) {
      return MESSAGE_NOT_NULL
    } else {
      return validateNumberField(String(item[propName]));
    }
  }

  if(max25Charactor.includes(propName)) {
    if (numberField.includes(propName)) {
      return validateNumberField(String(item[propName]));
    }
    return validateMaximumCharactorOnlyFacility(String(item[propName]), 25);
  }

  if(max128Charactor.includes(propName)) {
    return validateMaximumCharactorOnlyFacility(String(item[propName]), 128);
  }
  return null
};

export const validateDate = (item) => {
  if (item) {
    const regExp = /^(\d{4})\/(\d\d?)\/(\d\d?)$/;
    let matches = item.match(regExp);
    let isValid = matches;
    let maxDate = [0, 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    if (matches) {
      const month = parseInt(matches[2]);
      const date = parseInt(matches[3]);
      const year = parseInt(matches[1]);

      isValid = month <= 12 && month > 0;
      isValid &= date <= maxDate[month] && date > 0;

      const leapYear = (year % 400 == 0)
         || (year % 4 == 0 && year % 100 != 0);
      isValid &= month != 2 || leapYear || date <= 28;
    }

    return isValid ? null : MESSAGE_INCORRECT_DATE;
  }
  return null;
};

export const validateBlankRowData = (item, itemsInView, isDbCustomize, emptyText) => {
  if(isDbCustomize) {
    const removeItemKey = ['scope_id', 'category_id']
    itemsInView = itemsInView.filter(itemKey => !removeItemKey.includes(itemKey)) 
    const isEmptyScopeCategory = removeItemKey.every(itemKey => item[itemKey] === emptyText)
    return (item?.id === BLANK_ID && itemsInView.every((field) => item[field] === null || item[field] === '' || item[field] === undefined) && isEmptyScopeCategory) || (!item?.id && itemsInView.every((field) => item[field] === null || item[field] === '' || item[field] === undefined))
  }
  if (item?.id === BLANK_ID && itemsInView.every((field) => item[field] === null || item[field] === '' || item[field] === undefined)) {
    return true;
  }
  return false;
}

export default {
  regexMail,
  regexPassword,
  regexPhoneNumber
};

export const isPositiveInteger = (value) => {
  if (value === null || value === '') return false;
  return value > -1;
}

export const validateInputFieldCasbee = (input) => {
  if (!(input == null || input == undefined  || input === '')) {
    // Type input always number
    if (isNaN(Number(input))) {
      return MESSAGE_IS_NUMBER
    }
  } else {
    return i18n.t('casbee.error_enter_input_title_casbee');
  }
};
export const validateInputIsYearGHG = (input) => {
  if (!$_helper_isNumberType(input) || formatValue(input)?.length !== 4) {
    return false;
  }
  if (Number(input) > 9999 || Number(input) < 0) {
    return false;
  }
  return !input.includes('.');
};