<template>
  <v-btn @click="handleBtnClick" class="common-btn" :disabled="isDisable" depressed :class="type">
    <img v-if="icon" class="icon" :src="getIcon" alt="" />
    <slot class="icon"></slot>
    {{ label }}
  </v-btn>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'default',
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleBtnClick() {
      this.$emit('action');
    },
  },
  computed: {
    getIcon() {
      return require(`@/assets/images/${this.icon}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  margin-right: 8px !important;
}
</style>
