export const state = () => ({
    reductionTarget: null,
    durationReductionTarget: 1, // selected year
    reductionTargetIntegration: null
  });

export const mutations = {
  resetState(state) {
    state.reductionTarget = null;
    state.durationReductionTarget = 1;
  },
  updateLastedReductionTarget(state, data) {
    state.reductionTarget = data;
  },
  updateDurationReductionTarget(state, data) {
    state.durationReductionTarget = data;
  },
  setTargetSelect($state, data) {
    $state.reductionTargetIntegration = data
  }
};

export const actions = {
  resetState({ commit }) {
    commit('resetState');
  },

  setLastedReductionTarget({ commit }, data) {
    commit('updateLastedReductionTarget', data);
  },

  setDurationReductionTarget({ commit }, data) {
    commit('updateDurationReductionTarget', data);
  },

  setTargetSelect({ commit }, data) {
    commit('setTargetSelect', data);
  },
};

export const getters = {
  getReductionTargetIntegration: ($state) => $state.reductionTargetIntegration
};


export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
