import Vue from 'vue';
import useI18n from 'vue-i18n';
import jpMessage from './jp.json';
import enMessage from './en.json';
import arMessage from './ar.json';
import esMessage from './es.json';
import zh_cnMessage from './zh-cn.json';
import zh_twMessage from './zh-tw.json';
import viMessage from './vi.json';
Vue.use(useI18n);

const locale = (() => {
  const userData = JSON.parse(localStorage.getItem("vuex"))?.userData;
  const languageCode = userData?.language?.code || localStorage.getItem("language");
  return languageCode || 'ja';
})();

const messages = {
  ja: jpMessage,
  en: enMessage,
  'ar-AE': arMessage,
  es: esMessage,
  'zh': zh_cnMessage,
  'zh-TW': zh_twMessage,
  vi: viMessage
}

const formatDateTime = {
  short: {
    year: 'numeric', month: locale === 'ja'? 'short' :'numeric',
  },
  long: {
    year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'
  }
}

const formatAllLanguage = Object.fromEntries(Object.keys(messages).map(key => [key, formatDateTime]))

export default new useI18n({
  locale: locale, // set locale
  messages,
  fallbackLocale: 'ja',
  globalInjection: true,
  dateTimeFormats: formatAllLanguage
})