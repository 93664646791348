<template>
  <div>
    <v-dialog
      v-model="getDialog"
      @click:outside="handleClickOutSite"
      ref="dialog"
      max-width="464px"
      content-class="title-close history-comment"
    >
      <v-card>
        <v-card-title>
          <span>{{ $t("popup.title_log_confirm") }}</span>
          <div class="button-close">
            <img @click="close" src="@/assets/images/dialog/close.svg" class="button-close_close"/>
            <img @click="close" src="@/assets/images/dialog/close_active.svg" class="button-close_active"/>
          </div>
        </v-card-title>
        <v-card-text>
            <v-list-item
              v-for="(item, index) in contents"
              :key="index"
            >
              <v-list-item-content>
                <div class="item-content-header">
                  <div class="item-content-header-left">{{ item.name }}</div>
                  <div class="item-content-header-right">{{ item.date }}</div>
                </div>
                <v-list-item-subtitle v-text="item.content"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    contents: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  computed: {
    getDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('update:dialog', value);
      },
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    submit() {
      this.$emit('submit');
    },
    handleClickOutSite() {
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/components/popup/index.scss';
</style>
