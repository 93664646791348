import request from './base/request';
const preparePayload = (payload, isUpdate = false) => {
  const body = new FormData();
  const payloadBlob = new Blob([JSON.stringify(payload)], { type: 'application/json' });
  body.append('data', payloadBlob);
  if (isUpdate) {
    body.append('_method', 'put');
  }
  return body
}
const contactSupplierApi = (data) => {
  return request
    .post('/contact-supplier', data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};
const infoFormPrimaryDataApi = (primary_uuid) => {
  return request
    .get(`primary-data-form/${primary_uuid}`)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

const getInforPrimaryDataApiBeforeLogin = (primary_uuid) => {
  return request
    .get(`primary-data-form-before-login/${primary_uuid}`)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

const getLanguageSupplierApi = () => {
  return request
    .get('/language-supplier')
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error('err', err);
      throw err;
    });
};

const updateLanguageSupplierApi = (data) => {
  return request.post('/language-supplier', data)
}

const submitDataSupplierAction = (payload) => {
  return request.post('workflow-data-supllier', preparePayload(payload)).then((res) => {
    return res;
  }).catch((error) => {
    throw error;
  })
}


export { contactSupplierApi, infoFormPrimaryDataApi, getInforPrimaryDataApiBeforeLogin, getLanguageSupplierApi, updateLanguageSupplierApi, submitDataSupplierAction }
