const defaultVal = '0.00000000000000000000000'
const defaultValMore25 = '0.000000000000000000000000000000000000000000000000'
import {formatValue, $_helper_isNumberType} from '@/concerns/newRegisterData/wijmo.helper';

export const addThoundSandComma = (value, type = '', isFormatValue = false, isAlowMore25digit) => {
  if(value && !value?.toString()?.includes('.') && isFormatValue) {
    value += '.00'
  }
  if (type === 'chart-detail-ratio' && value === '-') {
    return value;
  }
  if (value === null) return null;
  if (!$_helper_isNumberType(value)) {
    return 0
  }
  if(value === defaultVal || defaultValMore25 === value) {
    return '0.00'
  }
  let numberRange = formatValue(value).toString().includes('-') ? 26 : 25;
  if(isAlowMore25digit) {
    numberRange = 50
  }
  let newValue = formatValue(value).toString().substring(0, numberRange).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
  let afterDot = newValue.split('.');
  if (afterDot[1]) {
    afterDot[1] = formatValue(afterDot[1]);

    if(afterDot[1].length === 2) {
      return `${afterDot[0]}.${afterDot[1].substring(0, 2)}`;
    }
    let numberOffCharactorAffterDot = afterDot[1].length
    for (let i = numberOffCharactorAffterDot; i > 2; i--) {
      if(afterDot[1][i - 1] !== '0') {
        break;
      }
      numberOffCharactorAffterDot -= 1 // minus last charactor if = 0
    }

    // if(indexNextZero === afterDot[1].length) {
    //   return `${afterDot[0]}.00`;
    // }

    let valueAffterDotFormat = afterDot[1].substring(0, numberOffCharactorAffterDot)
    if(valueAffterDotFormat === '0') {
      valueAffterDotFormat += '0'
    }

    return `${afterDot[0]}.${valueAffterDotFormat}`;
  }
  return afterDot[0];
}

export const addThoundSandCommaWithTwoDigit = (value, type = '', isFormatValue = false, isAlowMore25digit) => {
  if(value && !value?.toString()?.includes('.') && isFormatValue) {
    value += '.00'
  }
  if (type === 'chart-detail-ratio' && value === '-') {
    return value;
  }
  if (value === null) return null;
  if (!$_helper_isNumberType(value)) {
    return 0
  }
  if(value === defaultVal || defaultValMore25 === value) {
    return '0.00'
  }
  let numberRange = formatValue(value).toString().includes('-') ? 26 : 25;
  if(isAlowMore25digit) {
    numberRange = 50
  }
  let newValue = formatValue(value).toString().substring(0, numberRange).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
  let afterDot = newValue.split('.');
  if (afterDot[1]) {
    afterDot[1] = formatValue(afterDot[1]);

    if(afterDot[1].length === 2) {
      return `${afterDot[0]}.${afterDot[1].substring(0, 2)}`;
    }
    const indexOffFirstZero = afterDot[1].indexOf("0")
    const isDontHasZeroInFirstTwoCharctor = afterDot[1].length > 2 && afterDot[1][0] !== '0' && afterDot[1][1] !== '0'

    if(indexOffFirstZero < 0 || isDontHasZeroInFirstTwoCharctor) {
      let valueAffterFormat = afterDot[1].substring(0, 2)
      return `${afterDot[0]}.${valueAffterFormat}`;
    } else if(afterDot[1][0] !== '0' && afterDot[1][1] === '0') {
      return `${afterDot[0]}.${afterDot[1].substring(0, 2)}`;
    } else if(afterDot[1][0] === '0' && afterDot[1][1] !== '0') {
      return `${afterDot[0]}.${afterDot[1].substring(0, 3)}`;
    } else {
      let indexNextZero = 2
      for (let i = 2; i < afterDot[1].length; i++) {
        if(afterDot[1][i] !== '0') {
          break;
        }
        indexNextZero += 1
      }

      if(indexNextZero === afterDot[1].length) {
        return `${afterDot[0]}.00`;
      }

      let valueAffterDotFormat = afterDot[1].substring(0, indexNextZero + 2)
      if(valueAffterDotFormat === '0') {
        valueAffterDotFormat += '0'
      }

      return `${afterDot[0]}.${valueAffterDotFormat}`;
    }
    // return `${afterDot[0]}.${afterDot[1].substring(0, 2)}`;
  }
  return afterDot[0];
}

export const addThoundSandCommaDashboard = (value, type = '', isFormatValue = false, isAlowMore25digit) => {
  if(value && !value?.toString()?.includes('.') && isFormatValue) {
    value += '.00'
  }
  if (type === 'chart-detail-ratio' && value === '-') {
    return value;
  }
  if (value === null) return null;
  if (!$_helper_isNumberType(value)) {
    return 0
  }
  if(value === defaultVal || defaultValMore25 === value) {
    return '0.00'
  }
  let numberRange = formatValue(value).toString().includes('-') ? 26 : 25;
  if(isAlowMore25digit) {
    numberRange = 50
  }
  let newValue = formatValue(value).toString().substring(0, numberRange).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
  let afterDot = newValue.split('.');
  if (afterDot[1]) {
    afterDot[1] = formatValue(afterDot[1]);

    if(afterDot[1].length === 2) {
      return `${afterDot[0]}.${afterDot[1].substring(0, 2)}`;
    }
    const indexOffFirstZero = afterDot[1].indexOf("0")
    const isDontHasZeroInFirstTwoCharctor = afterDot[1].length > 2 && afterDot[1][0] !== '0' && afterDot[1][1] !== '0'

    if(indexOffFirstZero < 0 || isDontHasZeroInFirstTwoCharctor) {
      let valueAffterFormat = afterDot[1].substring(0, 2)
      return `${afterDot[0]}.${valueAffterFormat}`;
    } else if(afterDot[1][0] !== '0' && afterDot[1][1] === '0') {
      return `${afterDot[0]}.${afterDot[1].substring(0, 2)}`;
    } else if(afterDot[1][0] === '0' && afterDot[1][1] !== '0') {
      return `${afterDot[0]}.${afterDot[1].substring(0, 3)}`;
    } else {
      let indexNextZero = 2
      for (let i = 2; i < afterDot[1].length; i++) {
        if(afterDot[1][i] !== '0') {
          break;
        }
        indexNextZero += 1
      }

      if(indexNextZero === afterDot[1].length) {
        return `${afterDot[0]}.00`;
      }

      let valueAffterDotFormat = afterDot[1].substring(0, indexNextZero + 2)
      if(valueAffterDotFormat === '0') {
        valueAffterDotFormat += '0'
      }

      return `${afterDot[0]}.${valueAffterDotFormat}`;
    }
    // return `${afterDot[0]}.${afterDot[1].substring(0, 2)}`;
  }
  return afterDot[0];
}

export const roundingNumber = (num, decimalPlaces = 2) => {
  const result = Number(num).toFixed(decimalPlaces);
  return result;
}

export const listRangeYear = (startYear, endYear) => {
  const yearList = [];
  for (let year = endYear; year >= startYear; year--) {
    yearList.push(year);
  }

  return yearList;
}