export const state = () => ({
  listBranch: [],
});

export const mutations = {
  updateListBranch(state, data) {
    state.listBranch = data;
  },
};

export const actions = {
  setListBranch({ commit }, data) {
    commit('updateListBranch', data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
