import moment from 'moment';

export const formatDateTime = (dateTime) => {
  if (dateTime) {
    return moment.utc(dateTime).add(9, 'hours').format('YYYY/M/DD HH:mm ') + 'JST';
  }
};

export const formatDateTimeDataTable = (dateTime) => {
  if (dateTime) {
    return moment.utc(dateTime).add(9, 'hours').format('YYYY/MM/DD HH:mm:ss');
  }
};

export const formatNumber = (number, numberDecimals = 2) => {
  if (!number) {
    number = 0;
  }
  number = Number(number);
  if (Number.isInteger(number)) {
    return number.toLocaleString('ja-JP') + '.00';
  } else {
    const numberArr = number.toFixed(numberDecimals).split('.');
    const decimals = numberArr[1];
    return Number(numberArr[0]).toLocaleString('ja-JP') + '.' + decimals;
  }
};

export default {
  formatDateTime,
  formatNumber,
};
