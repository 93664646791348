import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import * as wjcCore from "@mescius/wijmo";
import "./filters";
import "./directives";
import moment from "moment";
import { extendMoment } from "moment-range";
import i18n from '@/lang/i18n';
extendMoment(moment);
import VueSocketIO from "vue-socket.io";

Vue.use(new VueSocketIO({
  debug: true,
  connection: process.env.VUE_APP_REALTIME_NODE_SEVER
}));
Vue.config.productionTip = false;

wjcCore.setLicenseKey(process.env.VUE_APP_WIJMO_LICENSE_KEY);

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
