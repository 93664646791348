import request from './base/request';
const EMISSION_SERVICES = 'calculation-emission';

const getScopesAndCategories = (params) => {
  return request
    .get(EMISSION_SERVICES, { params: params })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

export { getScopesAndCategories };
