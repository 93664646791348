<template>
  <div class="custom-checkbox" @click="toggleCheckbox">
    <div class="checkbox-box" :class="{ checked: isChecked }">
      <svg v-if="isChecked" width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.999756 3.8L4.59976 7.4L10.9998 1" stroke="white" stroke-width="1.5" stroke-linejoin="round" />
      </svg>
    </div>
    <label class="label">{{ label }}</label>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
  },
  computed: {
    isChecked() {
      return this.value;
    },
  },
  methods: {
    toggleCheckbox() {
      this.$emit('input', !this.value);
      this.$emit('change', !this.value);
    },
  },
};
</script>

<style lang="scss" scoped>

.custom-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 8px;
  width: max-content;
}

.checkbox-box {
  width: 16px;
  height: 16px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  transition: border-color 0.3s;
  background: $monoWhite;
}

.label {
  color: #404D50;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.42px;
}

.checked {
  border-color: $bgCheckBox;
  background-color: $bgCheckBox;
}

.checkmark {
  width: 16px;
  height: 16px;
}

.checked .checkmark {
  fill: #007bff; /* Change checkmark color to blue */
}
</style>
