export const state = () => ({
  breadCrumbText: [],
  statusAction: true,
  loadMore: 5,
  isFilter: false,
  isExpand: true,
  isSucess: false,
  isLoadingCommon: false,
  isLoadingTableOnly: false,
  isLoadingPopUp: false,
});

export const mutations = {
  updateBreadCrumbText(state, newText) {
    state.breadCrumbText = newText;
  },

  updateStatus(state, status) {
    state.statusAction = status;
  },

  loadMore(state, number) {
    if (number === undefined) {
      state.loadMore = null;
    } else {
      state.loadMore = 5;
    }
  },
  updateFilter(state, status) {
    state.isFilter = status;
  },
  updateIsExpand(state, newState) {
    state.isExpand = newState;
  },

  updateIsSuccess(state, status) {
    state.isSucess = status;
  },
  updateIsLoading(state, newVal) {
    state.isLoadingCommon = newVal
  },
  updateIsLoadingTable(state, newVal) {
    state.isLoadingTableOnly = newVal
  },
  updateIsLoadingPopUp(state, newVal) {
    state.isLoadingPopUp = newVal
  }
};

export const actions = {
  updateBreadCrumb({ commit }, newText) {
    commit('updateBreadCrumbText', newText);
  },

  updateStatusAction({ commit }, status) {
    commit('updateStatus', status);
  },

  loadMoreAction({ commit }, number) {
    commit('loadMore', number);
  },

  actionUpdateFilter({ commit }, status) {
    commit('updateFilter', status);
  },

  actionUpdateIsExpand({ commit }, status) {
    commit('updateIsExpand', status);
  },

  actionUpdateIsSuccess({ commit }, status) {
    commit('updateIsSuccess', status);
  },

  actionUpdateIsLoading({ commit }, newVal) {
    commit('updateIsLoading', newVal)
  },

  actionUpdateIsLoadingTable({ commit }, newVal) {
    commit('updateIsLoadingTable', newVal)
  },
  actionUpdateIsLoadingPopUp({ commit }, newVal) {
    commit('updateIsLoadingPopUp', newVal)
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
