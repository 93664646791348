<template>
  <div>
    <v-dialog v-model="getDialog" @click:outside="handleClickOutSite" :persistent="true" ref="dialog" max-width="464px">
      <v-card id="notification-popup">
        <v-card-title>
          <img class="" src="@/assets/images/dialog/notification.svg" />
          <span class="">{{ titleText || $t("register_reduction.title_popup_success") }}</span>
        </v-card-title>
        <v-card-text id="notification-message" class="text-message">
          {{ message }}
          <div v-if="showCheckbox" class="custom-checkbox" @click="toggleCheckbox">
            <div class="checkbox-box" :class="{ checked: isChecked }">
              <svg v-if="isChecked" width="12" height="9" viewBox="0 0 12 9" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M0.999756 3.8L4.59976 7.4L10.9998 1" stroke="white" stroke-width="1.5"
                  stroke-linejoin="round" />
              </svg>
            </div>
            <label class="label">{{ $t('pcaf_update.checkbox_hide_popup_notification_data_quality_score') }}</label>
          </div>
        </v-card-text>
        <v-card-actions>
          <common-button class="btn-displayed-one" :type="typeColor" @action="submit"
            :label="confirmText || $t('list_menu.label_decision')" id="notification-btn" />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import CommonButton from '@/components/commonApp/Button.vue';
import i18n from '@/lang/i18n';
export default {
  components: {CommonButton},
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    typeColor: {
      type: String,
      default: 'colored',
    },
    confirmText: {
      type: String,
      default: null,
    },
    titleText: {
      type: String,
      default: i18n.t("register_reduction.title_popup_success"),
    },
    isRedButton: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      required: true,
    },
    classes: {
      type: String,
      required: false,
    },
    titleText: {
      type: String,
    },
    showCheckbox: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isChecked: false
    };
  },
  mounted() {
  },
  computed: {
    getDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('update:dialog', value);
      },
    },
  },
  methods: {
    toggleCheckbox() {
      this.isChecked = !this.isChecked
      this.$emit('updateShowPopup', this.isChecked);
    },
    close() {
      this.$emit('close');
    },
    submit() {
      this.$emit('submit');
    },
    handleClickOutSite() {
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/components/popup/index.scss';

.text-message {
  line-height: 28px!important;
}
.custom-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 8px;
  max-width: fit-content;
}

.checkbox-box {
  width: 16px;
  height: 16px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  transition: border-color 0.3s;
  background: $monoWhite;
}
.checked {
  border-color: $blueMid;
  background-color: $blueMid;
}

.checkmark {
  width: 16px;
  height: 16px;
}

.checked .checkmark {
  fill: #007bff;
  /* Change checkmark color to blue */
}
</style>
