const join_word = '_'

export const makeNewId = (id, type = 0) => btoa(unescape(encodeURIComponent([id, type].join(join_word))))

export const checkIdIsBase64 = (id) => {
  try {
    let newId = atob(id)

    return /^[\d]{1,}_[0|1]{1}$/.test(newId)
  } catch (error) {
    return false
  }
}

export const splitId = (joinId) => {
  const [id, type] = atob(joinId).split(join_word)

  return {
    id: Number(id),
    type: Number(type)
  }
}

export const prepareMasterOrCustomizeDB = (data = []) => {
  return (data || []).map((item) => {
    return {
      ...item,
      id: makeNewId(item.id, item.type),
      origin_id: item.id
    }
  })
}

export const getDbCustomizeOrDbMasterById= (masterDb) => {
  let result = {}

  prepareMasterOrCustomizeDB(masterDb).forEach((item) => {
    if (!result[item.id]) {
      result[item.id] = {...item}
    }
  })

  return result
}

export const getMasterDbId = (dbMasterList, fuelData) => {
  return dbMasterList.find(item => item.item_name === fuelData);
}

