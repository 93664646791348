<template>
  <div
    class="pull-down-adjust pulldown"
    :style="'width: ' + width + '; height: ' + height + '; min-width: ' + minWidth + ';max-width:' + maxWidth"
    v-click-outside="closeSelection"
    :class="{ active: selectionShow, 'casbee-pulldown': isCasbeePulldown }"
  >
    <div class="selection-box-bridge" ref="box">
      <div
        class="selection-box"
        :style="'height: ' + height"
        :class="{ 'casbee-box': isCasbeePulldown, disabled: disabled }"
        @click="showSelection()"
      >
        <div v-if="showName(value)" class="input-box pull-down-text-color">
          {{ showName(value) }}
          <span class="helper-txt" v-if="suffixInOption && showSuffixInOption(value)">{{ suffixInOption }}</span>
        </div>
        <div v-else class="input-box placeholder-color">{{ getPlaceHolderText }}</div>
        <div v-if="!isCasbeePulldown" class="input-icon">
          <img class="img" :class="{'rotate-180': selectionShow}" src="@/assets/images/pulldown/pulldown-default.svg" />
          <img class="none-image" :class="{'rotate-180': selectionShow}" src="@/assets/images/pulldown/pulldown-default-hover.svg" />
        </div>
        <div v-else class="input-icon">
          <img class="img" :class="{'rotate-180': selectionShow}" src="@/assets/images/pulldown/casbee-pulldown-default.svg" />
          <img class="none-image" :class="{'rotate-180': selectionShow}" src="@/assets/images/pulldown/casbee-pulldown-hover.svg" />
          <img class="disabled-image" src="@/assets/images/pulldown/casbee-pulldown-disabled.svg" />
        </div>
      </div>
    </div>
    <div
      v-show="selectionShow"
      class="selection-pulldown"
      :style="'width: ' + boxWidth + 'px; border-radius: 8px'"
      id="style-scroll"
      :class="{
        'item-scroll-none': items.length <= 4,
        'down-layer': isCasbeePulldown,
      }"
    >
      <div
        v-for="(item, index) in items"
        v-bind:key="index"
        :class="[compareItem(item.value) ? 'selected-background' : '']"
        class="item pull-down-text-color"
        @click="selectedItem(item.value)"
      >
        {{ item.name || 'No Name' }}
        <span class="helper-txt" v-if="suffixInOption && item.is_selected">{{ suffixInOption }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: Array,
    width: {
      type: String,
      default: '150px',
    },
    model: String,
    initialValue: [Object, String, Number, null],
    placeHolderText: {
      type: String,
      default: null,
    },
    isSelectFirst: {
      type: Boolean,
      default: false,
    },
    suffixInOption: {
      type: String,
      default: '',
    },
    defaultIsSelected: {
      type: Boolean,
      default: false,
    },
    isCasbeePulldown: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '40px',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    widthPullDown: {
      type: Number,
      default: 0
    },
    minWidth: {
      type: String,
      default:'auto'
    },
    maxWidth: {
      type: String,
      default:'auto'
    },
  },
  data() {
    return {
      selectionShow: false,
      value: '',
      boxWidth: 0,
      resizeTimer: null,
      isSelected: false,
    };
  },
  computed: {
    getPlaceHolderText() {
      return this.placeHolderText || this.$t("dashboard_main.placeholder_select_item_label");
    }
  },
  mounted() {
    this.isSelected = this.defaultIsSelected;
    this.boxWidth = this.$refs.box.clientWidth || this.widthPullDown;
    this.onWindowResize();
    if (this.initialValue) {
      this.value = this.initialValue;
    }
  },
  methods: {
    showSelection() {
      if (this.disabled) return; // disable select
      this.selectionShow = !this.selectionShow;
    },
    showName(value) {
      const returnObj = this.items.filter((obj) => {
        if (typeof value === 'object') {
          if (JSON.stringify(obj.value).replaceAll('"', '') == JSON.stringify(value).replaceAll('"', '')) {
            return obj;
          }
        }
        if (obj.value == value) {
          return obj;
        }
      });
      return returnObj.length > 0 ? returnObj[0].name || 'No Name' : '';
    },
    selectedItem(item) {
      if (this.suffixInOption) {
        const itemOriginal = this.items.find((i) => i.value === item);

        if (itemOriginal && Object.prototype.hasOwnProperty.call(itemOriginal, 'is_selected')) {
          this.isSelected = itemOriginal.is_selected;
        } else {
          this.isSelected = false;
        }
      }
      this.value = item;
      this.selectionShow = !this.selectionShow;
      this.$emit('input', item);
    },
    showSuffixInOption(value) {
      const result = this.items.filter((obj) => {
        if (value === obj.value) {
          return true;
        }
        return false;
      });

      if (result.length) {
        return result[0].is_selected;
      }
      return false;
    },
    compareItem(item) {
      if (typeof item === 'object') {
        if (JSON.stringify(item).replaceAll('"', '') == JSON.stringify(this.value).replaceAll('"', '')) {
          return true;
        }
      }
      if (item == this.value) {
        return true;
      }
      return false;
    },
    closeSelection() {
      this.selectionShow = false;
    },
    onWindowResize() {
      window.addEventListener('resize', this.handleWindowResize);
    },
    offWindowResize() {
      window.removeEventListener('resize', this.handleWindowResize);
    },
    handleWindowResize(_) {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = setTimeout(() => {
        this.boxWidth = this.$refs.box.clientWidth;
      }, 100);
    },
  },
  watch: {
    initialValue() {
      if (this.initialValue) {
        this.value = this.initialValue;
      }
    },
    items() {
      if (this.isSelectFirst && this.items.length) {
        this.value = this.items[0].value;
      }
    },
    defaultIsSelected() {
      if (this.defaultIsSelected) {
        this.isSelected = true;
      }
    },
    width(value) {
      this.boxWidth = parseFloat(value);
    }
  },
  destroyed() {
    this.offWindowResize();
  },
};
</script>

<style lang="scss" scoped>
.helper-txt {
  color: #df4949;
  margin-left: 5px;
}

.pulldown {
  display: block;
  height: 40px;
  background: $monoOffWhite;
  box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
    0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
    0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
  border-radius: 4px;

  .selection-box-bridge {
    justify-content: space-between;
    padding-bottom: 4px;
    .selection-box {
      display: flex;
      height: 40px;
      padding: 7px 0px 9px 16px;
      width: 100%;
      height: 100%;
      margin-bottom: 0px;

      .input-box {
        display: flex;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: inherit;
        font-size: 14px;
        align-items: center;
      }

      .input-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px;
        margin-right: 6px;

        .img {
          display: block;
        }

        .none-image {
          display: none;
        }
        .disabled-image {
          display: none;
        }
      }
      &.casbee-box {
        background: $monoWhite;
        // border: 1px solid #999999;
        border-radius: 6px;
        padding: 6px 8px;
        .placeholder-color {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.05em;
          color: #cbcbcb;
        }
       
        .input-icon {
          margin-right: -1px;
        }
      }
      &.disabled {
        background: rgba(121, 134, 134, 0.12);
        border-radius: 4px;
      }
    }

    .selection-box:hover {
      .input-icon {
        .img {
          display: none;
        }
        .none-image {
          display: block;
        }
      }
    }
  }

  .selection-pulldown {
    // display: flex;
    position: absolute;
    max-height: 160px;
    background: #f7f7f2;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
      0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
      0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);

    z-index: 99;
    overflow-y: scroll;
    overflow-x: hidden;
    .item {
      height: 40px;
      padding: 7px 0px 9px 16px;
      border: 1px solid rgba(42, 42, 48, 0.1);
      cursor: pointer;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-family: 'Source Han Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;

      letter-spacing: 0.03em;
    }

    .item:hover {
      background: #e3eded;
    }

    &.down-layer {
      z-index: 4;
      .item {
        border-bottom: unset;
      }
    }
  }
}

.pull-down-adjust {
  .selection-box-bridge {
    .selection-box {
      .input-box {
        // width: calc(100vw - 175px);
        width: 100%;
      }
    }
  }
}

.pull-down-adjust {
  &.casbee-pulldown {
    &.active {
      .selection-box-bridge {
        .casbee-box {
          border: 2px solid $blueMid;
        }
      }
    }
  }
  &.casbee-type {
    &.active {
      .selection-box-bridge {
        .selection-box {
          border: 2px solid $blueMid;
        }
      }
    }
  }
}

#style-scroll::-webkit-scrollbar {
  width: 16px;
}

#style-scroll::-webkit-scrollbar-thumb {
  background-color: #bfd4d9;
  border-radius: 10px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
}

.selected-background {
  background: #e3eded;
  font-weight: 500 !important;
}

.item-scroll-none {
  overflow: hidden !important;
}

.placeholder-color {
  color: $monoMid;
}

.pull-down-text-color {
  color: $monoBlack;
}

@include desktop {
  .pulldown {
    display: block;
    height: 40px;
    background: $monoOffWhite;
    box-shadow: 0px 1.2365612983703613px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24), 0px 3.12735652923584px 2.866743326187134px 0px rgba(160, 181, 186, 0.17), 0px 6.379513740539551px 5.8478875160217285px 0px rgba(160, 181, 186, 0.13), 0px 13.140592575073242px 12.04554271697998px 0px rgba(160, 181, 186, 0.11), 0px 36px 33px 0px rgba(160, 181, 186, 0.07);
    border-radius: 4px;
    transition: 0.1s ease-out;

    .selection-box-bridge {
      padding-bottom: 4px;
      .selection-box {
        display: flex;
        padding: 7px 0px 9px 16px;
        width: 100%;
        height: 40px;
        // margin-bottom: 4px;

        .input-box {
          display: flex;
          width: 100%;
          align-items: center;
        }

        .input-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 5px;
          margin-right: 9px;

          .img {
            display: block;
          }

          .none-image {
            display: none;
          }
          .rotate-180 {
            transform: rotate(180deg);
          }
        }
        &.casbee-box {
          border: 1px solid #999999;
          .input-icon {
            margin-right: -1px;
          }
        }
      }
      .selection-box:hover {
        .input-icon {
          .img {
            display: none;
          }
          .none-image {
            display: block;
          }
        }
      }
    }

    .selection-pulldown {
      position: absolute;
      max-height: 160px;
      background: #f7f7f2;
      box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
        0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
        0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);

      z-index: 99;
      overflow-y: scroll;
      overflow-x: hidden;

      .item {
        font-size: 14px;
        height: 40px;
        padding: 7px 0px 9px 16px;
        border: 1px solid rgba(42, 42, 48, 0.1);
      }
    }
    &:hover {
      background: $monoWhite;
      box-shadow: 0px 0.18115200102329254px 0.6340319514274597px 0px rgba(160, 181, 186, 0.48), 0px 0.5008620619773865px 1.7530173063278198px 0px rgba(160, 181, 186, 0.31), 0px 1.2058829069137573px 4.220590114593506px 0px rgba(160, 181, 186, 0.24), 0px 4px 14px 0px rgba(160, 181, 186, 0.17);
    }
  }

  .item-scroll-none {
    overflow: hidden !important;
  }

  #style-scroll::-webkit-scrollbar {
    width: 16px;
  }

  #style-scroll::-webkit-scrollbar-thumb {
    background-color: #bfd4d9;
    border-radius: 10px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }
}
</style>
