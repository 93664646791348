const OFFSET_FULLWIDTH = 65248 
const REGEX_FULLWIDTH =/[Ａ-Ｚａ-ｚ０-９]/g // alphanumeric characters

export function convertFullWidthToHalfWidth(input) {
  return input.replace(REGEX_FULLWIDTH, function(match) {
    return String.fromCharCode(match.charCodeAt(0) - OFFSET_FULLWIDTH);
  });
}

export function convertNestedFullWidthToHalfWidth(data) {
  if (typeof data === 'string') {
    return convertFullWidthToHalfWidth(data);
  } else if (Array.isArray(data)) {
    return data.map(convertNestedFullWidthToHalfWidth);
  } else if (typeof data === 'object' && data !== null) {
    const convertedData = {};
    for (const key in data) {
      convertedData[key] = convertNestedFullWidthToHalfWidth(data[key]);
    }
    return convertedData;
  }
  return data;
}