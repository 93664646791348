<template>
  <div class="page-title">
    <h1 class="page-title__text">
      {{ title }}
      <span v-if="textOnlyJp" class="page-title__only-jp">
      {{ textOnlyJp }}
      </span>
    </h1>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    textOnlyJp: {
      type: String,
      default: '',
    }
  },
};
</script>

<style lang="scss" scoped>
.page-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 40px 0 48px;

  &__text {
    position: static;
    width: 100%;
    // height: 36px;
    left: 0;
    top: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 36px;
    letter-spacing: 0.05em;
    color: $goldMid;
  }
  &__only-jp {
    position: static;
    width: 100%;
    // height: 36px;
    left: 0;
    top: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 36px;
    letter-spacing: 0.05em;
    color: $goldMid;
    display: block;
  }
  &.emission-sub-title {
    .page-title__text {
      font-size: 18px;
    }
  }
}
@include desktop {
  .page-title {
    margin: unset;
    margin-bottom: 48px;

    &__text {
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 36px;
      letter-spacing: 0.05em;
      color: $goldMid;
    }
    &__only-jp {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 36px;
      letter-spacing: 0.05em;
      color: $goldMid;
      display: inline;
    }
    &.emission-sub-title {
      .page-title__text {
        font-size: 18px;
      }
    }
  }
}
</style>
