export const state = () => ({
  itemsSettings: {},
});

export const mutations = {
  updateSettings(state, newVal) {
    state.itemsSettings = newVal;
  },
};

export const actions = {
  actionUpdateSettings({ commit }, newVal) {
    commit('updateSettings', newVal);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
